import { Component, OnInit } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from 'angularfire2/storage';
import {AngularFirestore} from 'angularfire2/firestore';
import { FirebaseService } from '../../../services/firebase.service';
import { AngularFireAuthModule, AngularFireAuth } from 'angularfire2/auth';
import { Observable} from 'rxjs/Observable';
import { finalize } from 'rxjs/operators';
import { TransferService } from '../../../services/transfer.service';


@Component({
  selector: 'app-copydetail',
  templateUrl: './copydetail.component.html',
  styleUrls: ['./copydetail.component.sass']
})
export class CopydetailComponent implements OnInit {

  //request
  request:any
  facebook:boolean=false
  instagram:boolean=false
  showConfirmPic:boolean=false

  primaryText:any
  headline:any
  description:any

  constructor(
    private storage: AngularFireStorage, 
    private db: AngularFirestore,
    private transferService:TransferService,
    private afAuth: AngularFireAuth,
    private fbservice: FirebaseService
  ) { }

  ngOnInit() {
    this.getCopyDetails()
  }

  getCopyDetails(){
    this.request = this.transferService.getData2();
    console.log(this.request)
    if("facebookLink" in this.request)
           this.facebook = true;
    if("instagramLink" in this.request)
           this.instagram = true;
}


gotoLink(option){
  if(option=="facebook"){
    window.open(this.request.facebookLink, "_blank");
  }
  if(option=="instagram"){
    window.open(this.request.instagramLink, "_blank");
  }

}


sendCopy(){
  this.fbservice.sendCopy(this.request.id,this.request.userId, this.primaryText, this.headline, this.description).subscribe( detail=>{
     console.log(detail)
     this.showConfirmPic=true;
      
 }
 )
 }
 

}
