export enum AdObjectiveType {
  EVENT_RESPONSES = 'EVENT_RESPONSES',
  LEAD_GENERATION = 'LEAD_GENERATION',
  MESSAGES = 'MESSAGES',
  // QuickAds addition
  POST_ENGAGEMENT = 'POST_ENGAGEMENT',
  LINK_CLICKS = 'LINK_CLICKS',
  // New elements
  PAGE_LIKES = 'PAGE_LIKES', 
  PRODUCT_CATALOG_SALES = 'PRODUCT_CATALOG_SALES'

}
