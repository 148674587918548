import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Globals {

  scroll = {
    position: "right",
    barBackground: "#C9C9C9",
    barOpacity:"0.8",
    barWidth: "5",
    barBorderRadius: "20",
    barMargin: "0",
    gridBackground: "#D9D9D9",
    gridOpacity: "1",
    gridWidth: "2",
    gridBorderRadius: "20",
    gridMargin: "0",
    alwaysVisible: false,
    visibleTimeout: 1000
  }

  gender = [
    {viewValue: 'Male', value: ['0']},
    {viewValue: 'Female', value: ['1']},
    {viewValue: 'All', value: ['0','1']}
  ]

}
