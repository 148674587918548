import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';

// Dashboards

import {ProfileComponent} from './DemoPages/Dashboards/profile/profile.component';
import {LoginBoxedComponent} from './DemoPages/Dashboards/login-boxed/login-boxed.component';
import {UserComponent} from './DemoPages/Dashboards/user/user.component';
import {IndustryComponent} from './DemoPages/Dashboards/industry/industry.component';
import {ChatComponent} from './DemoPages/Dashboards/chat/chat.component';
import {ChatdetailComponent} from './DemoPages/Dashboards/chatdetail/chatdetail.component';

// Request
import {AudienceComponent} from './DemoPages/Dashboards/audience/audience.component';
import {ContentComponent} from './DemoPages/Dashboards/content/content.component';
import {InfluencerComponent} from './DemoPages/Dashboards/influencer/influencer.component';
import {CopyComponent} from './DemoPages/Dashboards/copy/copy.component';
import {CampaignComponent} from './DemoPages/Dashboards/campaign/campaign.component';

// Request Details
import {AudiencedetailComponent} from './DemoPages/Dashboards/audiencedetail/audiencedetail.component';
import {ContentdetailComponent} from './DemoPages/Dashboards/contentdetail/contentdetail.component';
import {InfluencerdetailComponent} from './DemoPages/Dashboards/influencerdetail/influencerdetail.component';
import {CopydetailComponent} from './DemoPages/Dashboards/copydetail/copydetail.component';
import {CampaigndetailComponent} from './DemoPages/Dashboards/campaigndetail/campaigndetail.component';

// Elements

import {StandardComponent} from './DemoPages/Elements/Buttons/standard/standard.component';
import {DropdownsComponent} from './DemoPages/Elements/dropdowns/dropdowns.component';
import {CardsComponent} from './DemoPages/Elements/cards/cards.component';
import {ListGroupsComponent} from './DemoPages/Elements/list-groups/list-groups.component';
import {TimelineComponent} from './DemoPages/Elements/timeline/timeline.component';
import {IconsComponent} from './DemoPages/Elements/icons/icons.component';

// Components

import {AccordionsComponent} from './DemoPages/Components/accordions/accordions.component';
import {TabsComponent} from './DemoPages/Components/tabs/tabs.component';
import {CarouselComponent} from './DemoPages/Components/carousel/carousel.component';
import {ModalsComponent} from './DemoPages/Components/modals/modals.component';
import {ProgressBarComponent} from './DemoPages/Components/progress-bar/progress-bar.component';
import {PaginationComponent} from './DemoPages/Components/pagination/pagination.component';
import {TooltipsPopoversComponent} from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';

// Tables

import {TablesMainComponent} from './DemoPages/Tables/tables-main/tables-main.component';

// Widgets

import {ChartBoxes3Component} from './DemoPages/Widgets/chart-boxes3/chart-boxes3.component';

// Forms Elements

import {ControlsComponent} from './DemoPages/Forms/Elements/controls/controls.component';
import {LayoutComponent} from './DemoPages/Forms/Elements/layout/layout.component';
import { AuthGuard } from './core/auth/auth.guard';

// Charts

const routes: Routes = [
  { path: 'login', component: LoginBoxedComponent },
  {
    path: '', component: BaseLayoutComponent, canActivate: [AuthGuard],
    children: [

      // Dashboads

      {
        path: 'users',
        component: UserComponent,
        data: {
          extraParameter: 'dashboardsMenu'
        }
      },
      {
        path: 'users/:userId',
        component: ProfileComponent,
        data: {
          extraParameter: 'dashboardsMenu'
        }
      },
      {path: 'chat', component: ChatComponent, data: {extraParameter: 'dashboardsMenu'}},
      {path: 'chatdetails', component: ChatdetailComponent, data: {extraParameter: 'dashboardsMenu'}},
      {path: 'industry', component: IndustryComponent, data: {extraParameter: 'dashboardsMenu'}},
      
      // Request
     
      {path: 'audience', component: AudienceComponent, data: {extraParameter: 'dashboardsMenu'}},
      {path: 'content', component: ContentComponent, data: {extraParameter: 'dashboardsMenu'}},
      {path: 'influencer', component: InfluencerComponent, data: {extraParameter: 'dashboardsMenu'}},
      {path: 'copy', component: CopyComponent, data: {extraParameter: 'dashboardsMenu'}},
      {path: 'campaign', component: CampaignComponent, data: {extraParameter: 'dashboardsMenu'}},


      // RequestDetails
      
      {
        path: 'contentdetails/:id',
        component: ContentdetailComponent,
        data: {
          extraParameter: 'dashboardsMenu'
        }
      },
      {
        path: 'influencerdetails/:id',
        component: InfluencerdetailComponent,
        data: {
          extraParameter: 'dashboardsMenu'
        }
      },
      {
        path: 'audiencedetails/:id',
        component: AudiencedetailComponent,
        data: {
          extraParameter: 'dashboardsMenu'
        }
      },
      {path: 'audiencedetails', component: AudiencedetailComponent, data: {extraParameter: 'dashboardsMenu'}},
      {path: 'contentdetails', component: ContentdetailComponent, data: {extraParameter: 'dashboardsMenu'}},
      {path: 'influencerdetails', component: InfluencerdetailComponent, data: {extraParameter: 'dashboardsMenu'}},
      {path: 'copydetails', component: CopydetailComponent, data: {extraParameter: 'dashboardsMenu'}},
      {path: 'campaigndetails', component: CampaigndetailComponent, data: {extraParameter: 'dashboardsMenu'}},
     
      // Elements
     
      {path: 'elements/buttons-standard', component: StandardComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/dropdowns', component: DropdownsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/icons', component: IconsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/cards', component: CardsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/list-group', component: ListGroupsComponent, data: {extraParameter: 'elementsMenu'}},
      {path: 'elements/timeline', component: TimelineComponent, data: {extraParameter: 'elementsMenu'}},

      // Components

      {path: 'components/tabs', component: TabsComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/accordions', component: AccordionsComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/modals', component: ModalsComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/progress-bar', component: ProgressBarComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/tooltips-popovers', component: TooltipsPopoversComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/carousel', component: CarouselComponent, data: {extraParameter: 'componentsMenu'}},
      {path: 'components/pagination', component: PaginationComponent, data: {extraParameter: 'componentsMenu'}},

      // Tables

      {path: 'tables/bootstrap', component: TablesMainComponent, data: {extraParameter: 'tablesMenu'}},

      // Widgets

      {path: 'widgets/chart-boxes-3', component: ChartBoxes3Component, data: {extraParameter: 'pagesMenu3'}},

      // Forms Elements

      {path: 'forms/controls', component: ControlsComponent, data: {extraParameter: 'formElementsMenu'}},
      {path: 'forms/layouts', component: LayoutComponent, data: {extraParameter: 'formElementsMenu'}},
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full'
      }
    ]
  },
  {path: '**', redirectTo: 'users', data: {extraParameter: 'dashboardsMenu'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
