import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IFBInterest } from 'src/app/core/user';
import { ProfileService } from 'src/app/core/user/services/profile.service';

@Component({
  selector: 'app-interest-selector',
  templateUrl: './interest-selector.component.html',
  styleUrls: ['./interest-selector.component.scss']
})
export class InterestSelectorComponent implements OnInit, OnDestroy {
  @Output()
  public onChange: EventEmitter<{
    included: IFBInterest[];
    excluded: IFBInterest[];
  }> = new EventEmitter();

  public includedInterests: IFBInterest[] = [];
  public excludedIntrests: IFBInterest[] = [];
  public interestCachedResults: IFBInterest[] = [];

  private destroy$: Subject<void> = new Subject();
  private searchCancelled$: Subject<void> = new Subject();

  constructor(private firebaseService: FirebaseService, private profileService: ProfileService) {}

  ngOnInit() {
    this.subscribeToWatchReset();
  }

  private subscribeToWatchReset() {
    this.profileService.resetSelectedIntrests$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      console.log('Should not exists.......');
      this.includedInterests = [];
      this.excludedIntrests = [];
      this.interestCachedResults = [];
    });
  }

  public onInterestInput(event: any, status) {
    this.searchCancelled$.next();
    // this.interestStatus = status;

    const searchString = event.target.value;
    if (searchString.length == 0) {
      this.interestCachedResults = [];
      return;
    }

    this.firebaseService
      .searchAudience(searchString)
      .pipe(takeUntil(this.destroy$), takeUntil(this.searchCancelled$))
      .subscribe((interestsResponse: any) => {
        console.log(interestsResponse.interests);
        this.interestCachedResults = interestsResponse.interests;
        return;
      });
  }

  public onIncludeInterest(interest /* interest */) {
    if (
      !this.interestIsInList(interest, this.includedInterests) &&
      !this.interestIsInList(interest, this.excludedIntrests)
    ) {
      this.includedInterests = [...this.includedInterests, interest];
    }

    this.triggerChange();
  }

  public onExcludeInterest(interest /* interest */) {
    if (
      !this.interestIsInList(interest, this.includedInterests) &&
      !this.interestIsInList(interest, this.excludedIntrests)
    ) {
      this.excludedIntrests = [...this.excludedIntrests, interest];
    }

    this.triggerChange();
  }

  public onRemoveInterestFromSelection(interestToRemove) {
    this.includedInterests = this.includedInterests.filter((interest) => {
      return interest.id !== interestToRemove.id;
    });
    this.excludedIntrests = this.excludedIntrests.filter((interest) => {
      return interest.id !== interestToRemove.id;
    });

    this.triggerChange();
  }

  private interestIsInList(interestToCheck: any, listToRunAgainst: any[]) {
    for (const interest of listToRunAgainst) {
      if (interest.id === interestToCheck.id) {
        return true;
      }
    }

    return false;
  }

  private triggerChange() {
    this.onChange.emit({
      included: this.includedInterests,
      excluded: this.excludedIntrests
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
