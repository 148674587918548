import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/core/auth/auth.service';
import { takeUntil, take } from 'rxjs/operators';
import { FirebaseLoginErrorCodes } from 'src/app/core/auth/enums';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login-boxed',
  templateUrl: './login-boxed.component.html',
  styleUrls: ['./login-boxed.component.scss']
})
export class LoginBoxedComponent implements OnInit, OnDestroy {

  public loginForm: FormGroup;
  public lastErrorMessage$: Subject<string> = new Subject();

  private destroy$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.initializeLoginForm();
  }

  private initializeLoginForm() {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required)
    });
  }

  public signInWithEmail() {
    if (this.loginForm.valid) {
      const { email, password } = this.loginForm.value;
      this.authService.loginWithEmail(email, password).
        pipe(take(1))
        .subscribe(
          (authState: firebase.auth.UserCredential) => {
            this.router.navigate(['users']);
          },
          (error: firebase.auth.AuthError) => {
            this.lastErrorMessage$.next(this.getErrorMessageFromError(error.code));
          });
    }
  }

  private getErrorMessageFromError(errorCode: string) {
    switch (errorCode) {
      case FirebaseLoginErrorCodes.INVALID_EMAIL:
        return 'Email is not valid';
      case FirebaseLoginErrorCodes.USER_NOT_FOUND:
        return 'User not found';
      case FirebaseLoginErrorCodes.INVALID_PASSWORD:
        return 'Wrong password';
    }
    return 'Unknown error'
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
