import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FirebaseService } from '../../../services/firebase.service';
import {
  AdObjectiveType,
  AgeSelectorType,
  CallToActionType,
  GenderType,
  IAudience,
  SpecialAdCategory,
  IInterestOutput
} from 'src/app/core/audience';
import * as moment from 'moment';
import { ProfileService } from 'src/app/core/user/services/profile.service';
import { Subject } from 'rxjs';
import { IFBInterest, IFBLanguage, IFBUserPost, IFBUserPostsAPI } from 'src/app/core/user';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { IFBQuickAd, IFBUserProfile } from '../../../core/user';
import { ICreateAudience } from '../../../core/audience';
import { IPushNotification } from './../../../core/notification/interfaces/push-notification.interface';

import {
  PublisherPlatformType,
  InstagramPositionsType,
  DevicePlatformType
} from '../../../core/api-contracts';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  public profile: IFBUserProfile;
  private currentUserId: string = null;
  public facebook = false;
  public instagram = false;
  public showConfirmPic = false;

  // Posts
  public userPosts: IFBUserPost[];
  public selectedPost: IFBUserPost = null;

  // Age
  public ageParameters = {
    minAge: 16,
    maxAge: 64,
    ageSelectorNumbers: []
  };
  public ageSelectorType = AgeSelectorType;

  // Gender
  public genderType = GenderType;
  private fbGenders: number[] = [];

  // Targeting
  private interestsTargettingObject: IInterestOutput = {
    flexible_spec: [],
    exclusions: []
  };
  private includedLanguages: IFBLanguage[] = [];

  // Ad objective type
  public availableAdObjectiveTypes = [AdObjectiveType.POST_ENGAGEMENT, AdObjectiveType.LINK_CLICKS];
  public selectedAdObjective: AdObjectiveType = AdObjectiveType.POST_ENGAGEMENT;

  // Call to action
  public availableCallToAction = [CallToActionType.LEARN_MORE];
  public selectedCallToAction: CallToActionType = CallToActionType.LEARN_MORE;

  // Special ad category
  private selectedSpecialAdCategory: SpecialAdCategory;

  // URL
  public selectedUrl: string;

  // Budget
  public selectedBudget = 100; // in cents

  // Date
  public selectedStartDate = moment().format('YYYY-MM-DD'); // '2020-08-19';
  public selectedStartTime = '00:00:00'; // 'hh-mm-ss';

  public selectedEndDate = moment()
    .add(7, 'd')
    .format('YYYY-MM-DD'); // '2020-08-19'
  public selectedEndTime = '00:00:00'; // 'hh-mm-ss';

  // When we click on Quick Ad submit, this is the server response to show in html
  public serverResponseStatus = '';

  // Location variables
  location: any;
  audience: any;
  targeting: any;
  audienceSize: any;
  // Map
  locationOption: any = 'Include';
  excludeLocation: any;
  optionStatus = true;
  locations: any;
  radius: number;
  zoom: any;
  noResultsLocation: any;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private firebaseService: FirebaseService,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    // For location
    private ref: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.getUserDetails();
    // For locations
    this.initAudience();

    // Initialize age selection
    for (
      let currNumber = this.ageParameters.minAge;
      currNumber < this.ageParameters.maxAge;
      currNumber++
    ) {
      this.ageParameters.ageSelectorNumbers.push(currNumber);
    }
  }

  // Post
  public onSelectPost(postToSelect: IFBUserPost) {
    this.selectedPost = postToSelect;
  }

  public formatPostDate(dateToFormat: string) {
    return moment(dateToFormat).format('MMM DD, YYYY');
  }

  // Age
  public onMinMaxAgeSelect(selectedAge: number, minOrMax: AgeSelectorType) {
    switch (minOrMax) {
      case AgeSelectorType.MaxAge:
        this.ageParameters.maxAge = selectedAge;
        break;
      case AgeSelectorType.MinAge:
        this.ageParameters.minAge = selectedAge;
        break;
    }

    if (this.ageParameters.minAge > this.ageParameters.maxAge) {
      this.ageParameters.minAge = this.ageParameters.maxAge;
    }
  }

  // Gender
  public onGenderSelectChanged(newSelectedGender: GenderType) {
    this.fbGenders = [];
    switch (newSelectedGender) {
      case GenderType.Male: {
        this.fbGenders.push(1);
        break;
      }
      case GenderType.Female: {
        this.fbGenders.push(2);
        break;
      }
      case GenderType.Both: {
        this.fbGenders.push(1);
        this.fbGenders.push(2);
        break;
      }
    }
  }

  // Targeting
  public onDetailedTargetingChange(newTargettingState) {
    this.interestsTargettingObject = newTargettingState;
  }

  // Objective
  public onSelectAdObjectiveType(newAdObjective: AdObjectiveType) {
    this.selectedAdObjective = newAdObjective;
  }

  public mapAdObjectiveTypeToText(adObjectiveType: AdObjectiveType): string {
    switch (adObjectiveType) {
      case AdObjectiveType.EVENT_RESPONSES:
        return 'Event responses';
      case AdObjectiveType.LEAD_GENERATION:
        return 'Lead generation';
      case AdObjectiveType.MESSAGES:
        return 'Messages';
      case AdObjectiveType.POST_ENGAGEMENT:
        return 'Post engagement';
      case AdObjectiveType.LINK_CLICKS:
        return 'Link clicks';
    }

    return 'Unknown ad objective';
  }

  public onSelectCallToAction(newCallToAction: CallToActionType) {
    this.selectedCallToAction = newCallToAction;
  }

  // Action
  public mapCallToActionToText(mapToAction: CallToActionType): string {
    switch (mapToAction) {
      case CallToActionType.BUY_NOW:
        return 'Buy now';
      case CallToActionType.EVENT_RSVP:
        return 'Event response';
      case CallToActionType.LEARN_MORE:
        return 'Learn more';
    }

    return 'Unknown call to action';
  }

  public onSpecialAdCategoryChange(adCategory: SpecialAdCategory) {
    this.selectedSpecialAdCategory = adCategory;
  }

  // Send QuickAdd to server
  public onSendQuickAdToServer() {
    this.serverResponseStatus = 'Sent...';
    // Generate exclusions for the facebook API
    let generatedExclusions = [];
    if (this.interestsTargettingObject && this.interestsTargettingObject.exclusions) {
      const exclusionKeys = Object.keys(this.interestsTargettingObject.exclusions);
      for (const objKey of exclusionKeys) {
        const arrItem = {};
        arrItem[objKey] = this.interestsTargettingObject.exclusions[objKey];

        generatedExclusions = [...generatedExclusions, arrItem];
      }
    }

    const generatedAudience: IAudience = {
      age_max: this.ageParameters.maxAge,
      age_min: this.ageParameters.minAge,
      createDate: new Date().toDateString(),
      geo_locations: {
        cities: this.targeting.geo_locations.cities,
        custom_locations: this.targeting.geo_locations.custom_locations
      },
      flexible_spec: this.interestsTargettingObject.flexible_spec,
      exclusions: generatedExclusions,
      genders: this.fbGenders,
      publisher_platforms: [
        PublisherPlatformType.Facebook,
        PublisherPlatformType.Instagram,
        PublisherPlatformType.Messenger
      ],
      facebook_positions: ['feed'],
      instagram_positions: [InstagramPositionsType.Stream, InstagramPositionsType.Explore],
      device_platforms: [DevicePlatformType.Mobile, DevicePlatformType.Desktop]
    };

    console.log('Generated Audience');
    console.log(generatedAudience);

    const createNewAudience: ICreateAudience = {
      userId: this.currentUserId,
      pageId: this.profile.activePageId,
      callToAction: this.selectedCallToAction,
      objective: this.selectedAdObjective,
      audience: generatedAudience
    };

    const startDateParsed = `${this.selectedStartDate}T${this.selectedStartTime}+z`;
    const endDateParsed = `${this.selectedEndDate}T${this.selectedEndTime}+z`;

    this.firebaseService
      .createStandAloneAudienceForUser(createNewAudience)
      .pipe(
        switchMap((createdAudience: { success: true; audienceId: string }) => {
          console.log('Audience Creation Response');
          console.log(createdAudience);

          const quickAdInformation: IFBQuickAd = {
            audience: createdAudience.audienceId,
            config: {
              budget: this.selectedBudget,
              start_time: startDateParsed,
              end_time: endDateParsed
            },
            content: {
              postId: this.selectedPost.id,
              url: this.selectedUrl,
              callToAction: this.selectedCallToAction
            },
            // media: {
            //   url: this.selectedPost.full_picture
            // },
            objective: this.selectedAdObjective,
            pageId: this.profile.activePageId,
            special_ad_categories: this.selectedSpecialAdCategory || SpecialAdCategory.NONE,
            status: 'PAUSED'
          };

          console.log('Quick Ad object to send');
          console.log(quickAdInformation);

          return this.firebaseService.postQuickAd(quickAdInformation);
        })
      )
      .subscribe((response) => {
        if (response) {
          // if the response was ok, then send a notification to the client phone:
          const pushNotification: IPushNotification = {
            users: [this.currentUserId],
            message: {
              notification: {
                title: 'Quick Ad Created',
                body: 'Your Quick Ad is ready to run. Tap to get started.'
              },
              apns: {
                payload: {
                  aps: {
                    'mutable-content': 1,
                    'content-available': 1,
                    sound: 'default',
                    destination: 'quickad'
                  }
                }
              }
            },
            validateOnly: false
          };
          this.firebaseService.notify(pushNotification)
           .subscribe(console.log, console.log, console.log);
           
          this.serverResponseStatus = 'OK';
          this.resetForm();
        }
      });
  }

  private getUserDetails() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap: ParamMap) => {
      if (paramMap.has('userId')) {
        this.currentUserId = paramMap.get('userId');

        this.firebaseService
          .getUserDetails(this.currentUserId)
          .pipe(takeUntil(this.destroy$))
          .subscribe((userProfile: IFBUserProfile) => {
            console.log('User Profile');
            console.log(userProfile);
            this.profile = userProfile;

            if ('facebookLink' in this.profile) {
              this.facebook = true;
            }
            if ('instagramLink' in this.profile) {
              this.instagram = true;
            }
          });

        this.firebaseService
          .getAllPost(this.currentUserId, '')
          .pipe(
            map((allUserPosts: IFBUserPostsAPI) => {
              console.log('User Posts');
              console.log(allUserPosts);
              allUserPosts.posts = allUserPosts.posts.slice(0, 15); 
              return allUserPosts.posts.map((post: IFBUserPost) => {
                // Facebook returns the IDs in this format: pageId_postId, so we need to remove the
                // first part before the '_' and use the second number as post ID to be submitted
                const realPostId: string = post.id.substring(
                  post.id.indexOf('_') + 1,
                  post.id.length
                );
                return { ...post, id: realPostId };
              });
            }),
          )
          .subscribe({
            next: (userPosts: IFBUserPost[]) => {
              this.userPosts = [...userPosts];
            }
          });
      }
    });
  }

  public gotoLink(option) {
    if (option === 'facebook') {
      window.open(this.profile.facebookLink, '_blank');
    }
    if (option === 'instagram') {
      window.open(this.profile.instagramLink, '_blank');
    }
  }

  private resetForm() {
    this.selectedPost = null;
    this.ageParameters.minAge = 16;
    this.ageParameters.maxAge = this.ageParameters.ageSelectorNumbers.length;
    this.selectedAdObjective = AdObjectiveType.EVENT_RESPONSES;
    this.selectedCallToAction = CallToActionType.BUY_NOW;
    this.selectedUrl = null;
    this.selectedBudget = 100;
    this.selectedStartTime = '00:00:00';
    this.selectedStartDate = moment().format('YYYY-MM-DD');
    this.selectedEndDate = moment()
      .add(7, 'd')
      .format('YYYY-MM-DD');
    this.selectedEndTime = '00:00:00';

    this.profileService.resetSelectedInterests();
    this.profileService.resetSelectedLanguages();
    this.location = {
      distance_unit: 'kilometer',
      latitude: '',
      longitude: '',
      radius: '16',
      name: 'Calgary,Alberta,Canada',
      status: false,
      type: ''
    };

    this.excludeLocation = {
      distance_unit: 'kilometer',
      latitude: 51.04473309999999,
      longitude: -114.07188309999998,
      radius: 16,
      name: 'Calgary,Alberta,Canada',
      status: false,
      type: ''
    };

    this.locations = { name: '', list: [] };
    this.radius = 10;
    this.zoom = 9;

    this.audience.locations = [];
    this.audience.addresses = [];
  }

  // LOCATION Methods
  public onMapClick(event, option) {
    console.log(event);

    this.location.latitude = event.latLng.lat();
    this.location.longitude = event.latLng.lng();
    this.location.name = '(' + this.location.latitude + ',' + this.location.longitude + ')';

    if (option) {
      this.audience.addresses.push({
        name: this.location.name,
        latitude: this.location.latitude,
        longitude: this.location.longitude,
        radius: this.location.radius,
        distance_unit: this.location.distance_unit,
        status: this.location.status
      });

      this.targeting.geo_locations.custom_locations.push({
        latitude: this.location.latitude,
        longitude: this.location.longitude,
        radius: 16,
        distance_unit: 'kilometer'
      });
    } else {
      this.audience.excludeaddresses.push({
        name: this.location.name,
        latitude: this.location.latitude,
        longitude: this.location.longitude,
        radius: this.location.radius,
        distance_unit: this.location.distance_unit,
        status: this.location.status
      });

      this.targeting.excluded_geo_locations.custom_locations.push({
        latitude: this.location.latitude,
        longitude: this.location.longitude,
        radius: 16,
        distance_unit: 'kilometer'
      });
    }
    this.firebaseService
      .getReachEstimate(this.targeting)
      .pipe(take(1))
      .subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });

    console.log(this.audience.addresses);

    this.ref.detectChanges();
  }

  public selectChangeHandlerLocation(event: any) {
    this.locationOption = event.target.value;
    this.optionStatus = this.locationOption === 'Include';
  }

  public getlocation(e) {
    if (e.target.value.length === 0) {
      this.locations.list = null;
      this.noResultsLocation = true;

      return;
    }

    this.firebaseService
      .searchLocation(e.target.value)
      .pipe(take(1))
      .subscribe((location: any) => {
        console.log(location);
        if (location.locations.length <= 0 || e.target.value === 0) {
          this.noResultsLocation = true;
          this.locations.list = null;
        } else {
          this.noResultsLocation = false;
          this.locations.list = location.locations;
        }
      });
  }

  public addLocation(key, name, type, countryName, option) {
    if (type === 'zip') {
      this.location.name = name + ',' + countryName;
    } else {
      this.location.name = name;
    }
    if (option) {
      if (type === 'city') {
        this.audience.locations.push({
          key,
          name,
          countryName,
          type,
          radius: '16',
          distance_unit: 'kilometer',
          status: false
        });
      } else {
        this.audience.locations.push({ key, name, countryName, type });
      }
      if (type === 'country') {
        this.targeting.geo_locations.countries.push(key);
      } else if (type === 'city') {
        if (!('cities' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.cities = [];
        }
        this.targeting.geo_locations.cities.push({ key, radius: '16', distance_unit: 'kilometer' });
      } else if (type === 'region') {
        if (!('regions' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.regions = [];
        }
        this.targeting.geo_locations.regions.push({ key });
      } else if (type === 'zip') {
        if (!('zips' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.zips = [];
        }
        this.targeting.geo_locations.zips.push({ key });
      } else if (type === 'geo_market') {
        if (!('geo_markets' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.geo_markets = [];
        }
        this.targeting.geo_locations.geo_markets.push({ key, name });
      } else if (type === 'electoral_district') {
        if (!('electoral_districts' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.electoral_districts = [];
        }
        this.targeting.geo_locations.electoral_districts.push({ key });
      } else if (type === 'country_group') {
        if (!('country_groups' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.country_groups = [];
        }
        this.targeting.geo_locations.country_groups.push(key);
      } else if (type === 'neighborhood') {
        if (!('neighborhoods' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.neighborhoods = [];
        }
        this.targeting.geo_locations.neighborhoods.push({ key, name });
      } else if (type === 'subcity') {
        if (!('subcities' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.subcities = [];
        }
        this.targeting.geo_locations.subcities.push({ key, name });
      }
    } else {
      if (type === 'city') {
        this.audience.excludelocations.push({
          key,
          name,
          countryName,
          type,
          radius: '16',
          distance_unit: 'kilometer',
          status: false
        });
      } else {
        this.audience.excludelocations.push({ key, name, countryName, type });
      }

      if (type === 'country') {
        this.targeting.excluded_geo_locations.countries.push(key);
      } else if (type === 'city') {
        if (!('cities' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.cities = [];
        }
        this.targeting.excluded_geo_locations.cities.push({
          key,
          radius: '16',
          distance_unit: 'kilometer'
        });
      } else if (type === 'region') {
        if (!('regions' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.regions = [];
        }
        this.targeting.excluded_geo_locations.regions.push({ key });
      } else if (type === 'zip') {
        if (!('zips' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.zips = [];
        }
        this.targeting.excluded_geo_locations.zips.push({ key });
      } else if (type === 'geo_market') {
        if (!('geo_markets' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.geo_markets = [];
        }
        this.targeting.excluded_geo_locations.geo_markets.push({ key, name });
      } else if (type === 'electoral_district') {
        if (!('electoral_districts' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.electoral_districts = [];
        }
        this.targeting.excluded_geo_locations.electoral_districts.push({ key });
      } else if (type === 'country_group') {
        if (!('country_groups' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.country_groups = [];
        }
        this.targeting.excluded_geo_locations.country_groups.push(key);
      } else if (type === 'neighborhood') {
        if (!('neighborhoods' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.neighborhoods = [];
        }
        this.targeting.excluded_geo_locations.neighborhoods.push({ key, name });
      } else if (type === 'subcity') {
        if (!('subcities' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.subcities = [];
        }
        this.targeting.excluded_geo_locations.subcities.push({ key, name });
      }
    }

    this.firebaseService
      .getReachEstimate(this.targeting)
      .pipe(take(1))
      .subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    this.locations.list = null;
    this.locations.name = null;
    console.log(this.audience);
  }

  public removeLocation(key, type) {
    let locationType;
    if (type === 'city') {
      locationType = 'cities';
    } else if (type === 'subcity') {
      locationType = 'subcities';
    } else {
      locationType = type + 's';
    }

    const locationId = this.audience.locations.map((res) => res.key).indexOf(key);

    if (locationId >= 0) {
      const locationId2 = this.targeting.geo_locations[locationType]
        .map((res) => res.key)
        .indexOf(key);
      this.audience.locations.splice(locationId, 1);
      this.targeting.geo_locations[locationType].splice(locationId2, 1);
      this.firebaseService
        .getReachEstimate(this.targeting)
        .pipe(take(1))
        .subscribe((estimate: any) => {
          this.audienceSize = estimate.estimate.users
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        });
    }

    this.ref.detectChanges();
  }

  public showSlideBarLocation(locationName, option) {
    if (option) {
      for (let i = 0; i < this.audience.locations.length; i++) {
        if (this.audience.locations[i].name === locationName) {
          this.audience.locations[i].status = true;
          break;
        }
      }
    } else {
      for (let i = 0; i < this.audience.excludelocations.length; i++) {
        if (this.audience.excludelocations[i].name === locationName) {
          this.audience.excludelocations[i].status = true;
          break;
        }
      }
    }
    this.ref.detectChanges();
  }

  public radiusChange(locationKey, option) {
    console.log(locationKey);
    if (this.radius / 10 >= 1 && this.radius / 10 < 2) {
      this.zoom = 9;
    } else if (this.radius / 10 >= 2 && this.radius / 10 < 3) {
      this.zoom = 8;
    } else {
      this.zoom = 7;
    }

    if (option) {
      for (let i = 0; i < this.audience.locations.length; i++) {
        if (this.audience.locations[i].key === locationKey) {
          this.audience.locations[i].radius = this.radius;
          this.audience.locations[i].status = false;
          console.log(this.targeting.geo_locations.cities);
          const locationId = this.targeting.geo_locations.cities
            .map((res) => res.key)
            .indexOf(locationKey);
          console.log(locationId);

          this.targeting.geo_locations.cities[locationId].radius = this.radius;

          this.firebaseService
            .getReachEstimate(this.targeting)
            .pipe(take(1))
            .subscribe((estimate: any) => {
              this.audienceSize = estimate.estimate.users
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            });
          break;
        }
      }
    } else {
      for (let i = 0; i < this.audience.excludelocations.length; i++) {
        if (this.audience.excludelocations[i].key === locationKey) {
          this.audience.excludelocations[i].radius = this.radius;
          this.audience.excludelocations[i].status = false;
          console.log(this.targeting.excluded_geo_locations.cities);
          const locationId = this.targeting.excluded_geo_locations.cities
            .map((res) => res.key)
            .indexOf(locationKey);
          console.log(locationId);

          this.targeting.excluded_geo_locations.cities[locationId].radius = this.radius;

          this.firebaseService
            .getReachEstimate(this.targeting)
            .pipe(take(1))
            .subscribe((estimate: any) => {
              this.audienceSize = estimate.estimate.users
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            });
          break;
        }
      }
    }

    this.ref.detectChanges();
  }

  public placeChanged(place, option) {
    console.log(place);
    this.location.name = place.formatted_address;
    this.location.latitude = place.geometry.location.lat();
    this.location.longitude = place.geometry.location.lng();
    console.log(this.location);

    if (option) {
      this.audience.addresses.push({
        name: this.location.name,
        latitude: this.location.latitude,
        longitude: this.location.longitude,
        radius: this.location.radius,
        distance_unit: this.location.distance_unit,
        status: this.location.status
      });

      this.targeting.geo_locations.custom_locations.push({
        latitude: this.location.latitude,
        longitude: this.location.longitude,
        radius: 16,
        distance_unit: 'kilometer'
      });
    } else {
      this.audience.excludeaddresses.push({
        name: this.location.name,
        latitude: this.location.latitude,
        longitude: this.location.longitude,
        radius: this.location.radius,
        distance_unit: this.location.distance_unit,
        status: this.location.status
      });

      this.targeting.excluded_geo_locations.custom_locations.push({
        latitude: this.location.latitude,
        longitude: this.location.longitude,
        radius: 16,
        distance_unit: 'kilometer'
      });
    }
    this.firebaseService
      .getReachEstimate(this.targeting)
      .pipe(take(1))
      .subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });

    this.ref.detectChanges();
  }

  public removeAddress(locationName) {
    const locationId = this.audience.addresses.map((res) => res.name).indexOf(locationName);

    if (locationId >= 0) {
      this.audience.addresses.splice(locationId, 1);
      this.targeting.geo_locations.custom_locations.splice(locationId, 1);
      this.firebaseService
        .getReachEstimate(this.targeting)
        .pipe(take(1))
        .subscribe((estimate: any) => {
          this.audienceSize = estimate.estimate.users
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          return;
        });
    }

    const locationId2 = this.audience.excludeaddresses.map((res) => res.name).indexOf(locationName);

    if (locationId2 >= 0) {
      this.audience.excludeaddresses.splice(locationId, 1);
      this.targeting.excluded_geo_locations.custom_locations.splice(locationId, 1);
      this.firebaseService
        .getReachEstimate(this.targeting)
        .pipe(take(1))
        .subscribe((estimate: any) => {
          this.audienceSize = estimate.estimate.users
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        });
    }

    this.ref.detectChanges();
  }

  public addressRadiusChange(locationName, option) {
    if (this.radius / 10 >= 1 && this.radius / 10 < 2) {
      this.zoom = 9;
    } else if (this.radius / 10 >= 2 && this.radius / 10 < 3) {
      this.zoom = 8;
    } else {
      this.zoom = 7;
    }

    if (option) {
      // this.location.radius= this.radius
      for (let i = 0; i < this.audience.addresses.length; i++) {
        if (this.audience.addresses[i].name === locationName) {
          this.audience.addresses[i].radius = this.radius;
          this.audience.addresses[i].status = false;

          this.targeting.geo_locations.custom_locations[i].radius = this.radius;

          this.firebaseService
            .getReachEstimate(this.targeting)
            .pipe(take(1))
            .subscribe((estimate: any) => {
              this.audienceSize = estimate.estimate.users
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            });
          break;
        }
      }
    } else {
      for (let i = 0; i < this.audience.excludeaddresses.length; i++) {
        if (this.audience.excludeaddresses[i].name === locationName) {
          this.audience.excludeaddresses[i].radius = this.radius;
          this.audience.excludeaddresses[i].status = false;

          this.targeting.excluded_geo_locations.custom_locations[i].radius = this.radius;

          this.firebaseService
            .getReachEstimate(this.targeting)
            .pipe(take(1))
            .subscribe((estimate: any) => {
              this.audienceSize = estimate.estimate.users
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            });
          break;
        }
      }
    }

    this.ref.detectChanges();
  }

  // Audience
  private initAudience() {
    this.audience = {
      age_min: 13,
      age_max: 14,
      gender: 2,
      interests: [[]],
      exclusion: [],
      locations: [],
      excludelocations: [],
      addresses: [],
      excludeaddresses: [],
      languages: [],
      connection: null,
      connectionPages: [],
      friendsconnectionPages: [],
      excludeconnectionPages: []
    };

    this.location = {
      distance_unit: 'kilometer',
      latitude: '',
      longitude: '',
      radius: '16',
      name: 'Calgary,Alberta,Canada',
      status: false,
      type: ''
    };

    this.excludeLocation = {
      distance_unit: 'kilometer',
      latitude: 51.04473309999999,
      longitude: -114.07188309999998,
      radius: 16,
      name: 'Calgary,Alberta,Canada',
      status: false,
      type: ''
    };

    this.locations = { name: '', list: [] };
    this.radius = 10;
    this.zoom = 9;

    this.targeting = {
      geo_locations: {
        custom_locations: []
      },
      excluded_geo_locations: {
        custom_locations: []
      },
      publisher_platforms: ['facebook', 'instagram', 'messenger', 'audience_network'],
      age_max: 65,
      age_min: 13,
      genders: [0, 1],
      flexible_spec: [{}],
      exclusions: {},
      locales: [],
      connections: [],
      excluded_connections: [],
      friends_of_connections: []
    };
  }

  // END LOCATION METHODS

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
