import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransferService {

  constructor() { }

  data:any;
  data2:any;
  data3:any;

  setData(data){
    this.data = data;
  }
  setData2(data){
    this.data2 = data;
  }
  setData3(data){
    this.data3 = data;
  }

  getData(){
    let temp = this.data;
    this.data = this.clearData();
    return temp;
  }

  getData2(){
    let temp = this.data2;
    this.data2 = this.clearData();
    return temp;
  }

  getData3(){
    let temp = this.data3;
    this.data3 = this.clearData();
    return temp;
  }

  clearData2(){
    this.data2 = undefined;
  }

  clearData3(){
    this.data3 = undefined;
  }

  clearData(){
    this.data = undefined;
  }
}
