import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from 'angularfire2/storage';
import {AngularFirestore} from 'angularfire2/firestore';
import { FirebaseService } from '../../../services/firebase.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable} from 'rxjs/Observable';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { TransferService } from '../../../services/transfer.service';
import { ActivatedRoute, ParamMap} from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-influencerdetail',
  templateUrl: './influencerdetail.component.html',
  styleUrls: ['./influencerdetail.component.scss']
})
export class InfluencerdetailComponent implements OnInit, OnDestroy {

  // request
  request: any;
  requestId: string;
  facebook = false;
  instagram = false;
  showConfirmPic = false;

  primaryText: any;
  headline: any;
  description: any;

  influencer: any = {};


  // Main task
  task: AngularFireUploadTask;
  image: any;

  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: Observable<string>;

  // State for dropzone CSS toggling
  isHovering: boolean;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    private transferService: TransferService,
    private afAuth: AngularFireAuth,
    private fbservice: FirebaseService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.getInfluencerDetails();
  }



  getInfluencerDetails() {

this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap: ParamMap) => {
  if (paramMap.has('id')) {
    this.requestId = paramMap.get('id');
  }
  this.fbservice.getInfluencerDetails(this.requestId).pipe(takeUntil(this.destroy$)).subscribe(detail => {
    this.request = detail;
    console.log(this.request);

    if ('facebookLink' in this.request) {
      this.facebook = true;
    }
    if ('instagramLink' in this.request) {
      this.instagram = true;
    }
  });
});
}


gotoLink(option) {
  if (option == 'facebook') {
    window.open(this.request.facebookLink, '_blank');
  }
  if (option == 'instagram') {
    window.open(this.request.instagramLink, '_blank');
  }

}

sendInfluencer() {
  this.influencer.primaryText = this.primaryText;
  this.influencer.headline = this.headline;
  this.influencer.description = this.description;
  this.influencer.image = this.image;
  console.log(this.influencer);

  this.fbservice.sendInfluencer(this.request.id, this.request.userId, this.influencer).subscribe( detail => {
     console.log(detail);
     this.showConfirmPic = true;

 });
 }


 toggleHover(event: boolean) {
  this.isHovering = event;
}


startUpload(event: FileList) {
  // The File object
  const file = event.item(0);

  // Client-side validation example
  if (file.type.split('/')[0] !== 'image') {
    console.error('unsupported file type :( ');
    return;
  }

  // The storage path
  const path = `test/${new Date().getTime()}_${file.name}`;

  // Totally optional metadata
  const customMetadata = { app: 'My AngularFire-powered PWA!' };

  // The main task
  this.task = this.storage.upload(path, file, { customMetadata });
  const ref = this.storage.ref(path);

  // Progress monitoring
  this.percentage = this.task.percentageChanges();
  this.snapshot   = this.task.snapshotChanges();


  // The file's download URL
  this.task.snapshotChanges().pipe(
    finalize(() => {
      this.downloadURL = ref.getDownloadURL();
      this.downloadURL.subscribe(url => {this.image = url; });

    })
  )
  .subscribe();

}

// Determines if the upload task is active
isActive(snapshot) {
  return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
}


// END LOCATION METHODS

 public ngOnDestroy() {
   this.destroy$.next();
   this.destroy$.complete();
 }
}

