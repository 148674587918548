import {Component, ViewEncapsulation, ViewChild, AfterViewInit, OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { AngularFirestore, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireStorage,  AngularFireUploadTask} from 'angularfire2/storage';
import { Observable, timer,isObservable} from 'rxjs';
import { map, take, tap ,finalize } from 'rxjs/operators';
import { FirebaseService } from '../../../services/firebase.service';
import { Globals } from '../../../services/globals.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.css']
})
export class IndustryComponent implements OnInit {

  industries:any
  industry:any
 


  constructor(
    private fbservice: FirebaseService
  ) 
  { 
   
  }

  ngOnInit() {
    this.industryInit()
  }

  industryInit(){
    this.industries = []
  
    this.fbservice.getIndustries().subscribe((rest) =>{
        
        this.industries = rest
    
      
    })
  
  }


  addIndustry(){
    this.fbservice.addIndustry(this.industry).subscribe((rest) =>{
        
     console.log(rest)
  
    
  })
  }


  deleteIndustry(industryId){
    this.fbservice.deleteIndustry(industryId).subscribe((rest) =>{
        
      console.log(rest)
   
     
   })
  }

}
