import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IFBLanguage } from 'src/app/core/user';
import { ProfileService } from 'src/app/core/user/services/profile.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {

  @Output()
  public onChange: EventEmitter<any> = new EventEmitter();

  public cachedLanguageResults: IFBLanguage[] = [];
  public includedLanguages: IFBLanguage[] = [];

  private searchCancelled$: Subject<void> = new Subject();
  private destroy$: Subject<void> = new Subject();

  constructor(
    private firebaseService: FirebaseService,
    private profileService: ProfileService
    ) {}


  ngOnInit() {
    this.subscribeToWatchReset();
  }

  private subscribeToWatchReset() {
    this.profileService.resetSelectedIntrests$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.includedLanguages = [];
      this.cachedLanguageResults = [];
    });
  }


  public onLanguageInput(event) {
    const searchString = event.target.value;

    this.searchCancelled$.next();

    if (searchString.length == 0) {
      this.cachedLanguageResults = [];
      return;
    }

    this.firebaseService
      .searchLanguage(searchString)
      .pipe(takeUntil(this.destroy$), takeUntil(this.searchCancelled$))
      .subscribe((locale: any) => {
        const languagesFound = locale.locales;
        this.cachedLanguageResults = languagesFound;
      });
  }

  public onIncludeLanguage(language) {
    if (!this.languageIsInList(language, this.includedLanguages)) {
      this.includedLanguages.push(language);
    }

    this.triggerChange();
  }

  public onRemoveLanguageFromSelection(language) {
    this.includedLanguages = this.includedLanguages.filter((languageFiltered) => {
      return languageFiltered.key !== language.key;
    });

    this.triggerChange();
  }

  private languageIsInList(languageToCheck: IFBLanguage, listToRunAgainst: IFBLanguage[]) {
    for (const language of listToRunAgainst) {
      if (language.key === languageToCheck.key) {
        return true;
      }
    }

    return false;
  }

  private triggerChange() {
    this.onChange.emit({
      included: this.includedLanguages
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
