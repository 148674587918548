import { Component, OnInit } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from 'angularfire2/firestore';
import { AngularFireAuthModule, AngularFireAuth } from 'angularfire2/auth';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import { FirebaseService } from '../../../services/firebase.service';
import {Globals } from '../../../services/globals.service';
import { TransferService } from '../../../services/transfer.service';

@Component({
  selector: 'app-audience',
  templateUrl: './audience.component.html',
  styleUrls: ['./audience.component.css']
})
export class AudienceComponent implements OnInit {
  requests:any
  query: any= "desc"
  previousRequestsSize: number = 0;
  request:any

  constructor(
    private db:AngularFirestore,
    private router: Router,
    private fbservice: FirebaseService,
    private transferService:TransferService,
    private afAuth: AngularFireAuth
  ) { 
  
  }



  ngOnInit() {
  // this.afAuth.auth.currentUser.getIdToken(true).then((idToken)=>{
      
     // this.transferService.setData(idToken)
      this.getAudienceRequests()
  }


  redirect(requestId) {
    this.fbservice.getAudienceDetails(requestId).subscribe( detail=>{
      this.request = detail
      this.transferService.setData2(this.request)
      this.router.navigate(['/audiencedetails']);
  }
  )
      
  } 




  scrollRequestTable(event){
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      console.log("End"); 
     this.loadAdditionalRequests();
   
    }
  }

  loadAdditionalRequests(){
    var lastRequest = this.requests[this.requests.length - 1].id;
    if(this.previousRequestsSize == this.requests.length){
      return;
    }
    this.previousRequestsSize = this.requests.length;
    //this.afAuth.auth.currentUser.getIdToken(true).then((idToken)=>{
     // console.log(idToken)
     // this.transferService.setData(idToken)
      this.fbservice.getAudienceRequest(this.query, lastRequest).subscribe( res => {
        Array.prototype.push.apply(this.requests,res)
        console.log(this.requests)    
      if(this.requests.length <= 30 ){
            this.loadAdditionalRequests();
      }  
      })
        
   // })
    
  }
  
  getAudienceRequests(){
    this.requests = [];
    this.previousRequestsSize = 0;
    this.fbservice.getAudienceRequest(this.query,"").subscribe( res => {    
    this.requests = res;
    console.log(this.requests)
    this.loadAdditionalRequests();
  })
    
}
    
 

}
