import { Component, OnInit } from '@angular/core';
import * as Chatkit from '@pusher/chatkit-client';
import { FirebaseService } from 'src/app/services/firebase.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-chatdetail',
  templateUrl: './chatdetail.component.html',
  styleUrls: ['./chatdetail.component.css']
})
export class ChatdetailComponent {

  activeRoom: string = null;

  title = 'Angular Chatroom';
  messages = [];
  users = [];
  currentUser: Chatkit.CurrentUser;

  username = 'AdvozAdmin';
  availableRooms = [];

  constructor(private firebaseService: FirebaseService) { }

  _message = '';
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    this._message = value;
  }

  sendMessage() {
    const { message, currentUser } = this;
    currentUser.sendMessage({
      text: message,
      roomId: this.activeRoom,
    });
    this.message = '';
  }

  // On 'Login'
  addUser() {

    this.firebaseService.postFirebaseFunction('registerchatuser', { username: this.username })
      .subscribe((data) => {
        // The token provider
        const tokenProvider = new Chatkit.TokenProvider({
          url: this.firebaseService.getFirebaseFunctionURL() + 'authenticatechatkit'
        });

        let chatInstance: string;

        if (environment.production) {
          chatInstance = 'v1:us1:a8e5e749-5223-48aa-8c38-a3244f74817d';
        } else {
          chatInstance = 'v1:us1:7d09d776-0f63-4962-b4c3-ffd78f16c2de';
        }

        const chatManager = new Chatkit.ChatManager({
          instanceLocator: chatInstance,
          userId: this.username,
          tokenProvider
        });
        this.loadUserRooms(this.username);
        return chatManager
          .connect()
          .then(currentUser => {
            this.currentUser = currentUser;
            this.users = currentUser.users;
          });
      });
  }

  loadUserRooms(userID: string) {
    this.firebaseService.getFirebaseFunction('get_user_rooms?user_id=' + userID, { user_id: userID }).subscribe(
      (rooms: []) => {
        this.availableRooms = rooms;
      }
    );
  }

  toggleActiveRoom(roomID: string) {
    this.messages = [];
    this.activeRoom = roomID;

    this.currentUser.subscribeToRoom({
      roomId: roomID,
      messageLimit: 100,
      hooks: {
        onMessage: message => {
          this.messages.push(message);
        },
        onPresenceChanged: (state, user) => {
          this.users = this.currentUser.users;
          this.users = this.currentUser.users.sort((a, b) => {
            if (a.presence.state === 'online') {
              return -1;
            }
            return 1;
          });
        },
      },
    }).then(() => {
      console.log(this.currentUser);
    });
  }
}
