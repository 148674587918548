import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByKey'
})
export class SortByKeyPipe implements PipeTransform {

  transform(collection: any[], sortKey: string): any {
    return collection.sort((a, b) => {
      const keyA = a[sortKey];
      const keyB = b[sortKey];
      // Compare
      if (keyA > keyB) {
        return -1;
      }
      if (keyA < keyB) {
        return 1;
      }
      return 0;
    });
  }

}
