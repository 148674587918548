import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { Router } from '@angular/router';
import { FirebaseService } from '../../../services/firebase.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IFbUserApi, User } from '../../../core/user';
import { UtilitiesService } from '../../../services/utilities.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
  public users: User[] = [];
  query: any = 'desc';
  previousUsersSize = 0;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private db: AngularFirestore,
    private router: Router,
    private firebaseService: FirebaseService,
    private utilitiesService: UtilitiesService
  ) { }

  public ngOnInit() {
    this.getUsers();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onUsersScroll(event) {
    // TODO: progressive users load
  }

  private loadAdditionalUsers() {

    const lastUser = this.users[this.users.length - 1].userId;
    if (this.previousUsersSize === this.users.length) {
      console.log('All users loaded: ');
      console.log(this.users);
      return;
    }

    this.previousUsersSize = this.users.length;

    this.firebaseService.getAllUsers(this.query, lastUser)
      .pipe(take(1))
      .subscribe((users: IFbUserApi[]) => {
        const moreUsers = users.map((fbUser: IFbUserApi) => new User(fbUser));
        this.users = [...this.users, ...moreUsers];
        if (this.users.length <= 200) {
          this.loadAdditionalUsers();
        }
      });

  }

  private getUsers() {
    this.previousUsersSize = 0;

    this.firebaseService.getAllUsers(this.query, '')
      .pipe(takeUntil(this.destroy$)).subscribe({

        next: (fbUsers: IFbUserApi[]) => {
          this.users = fbUsers.map((fbUser: IFbUserApi) => new User(fbUser));
          this.loadAdditionalUsers();
        }
      });

  }
}
