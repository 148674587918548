import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { FirebaseService } from '../../../services/firebase.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { TransferService } from '../../../services/transfer.service';
import {
  AdObjectiveType,
  CallToActionType,
  SpecialAdCategory,
  AgeSelectorType,
  GenderType,
  IInterestOutput,
  IAudience,
  ICreateAudience
} from '../../../core/audience';
import * as moment from 'moment';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IFBLanguage, IFBUserProfile } from 'src/app/core/user';
import { PublisherPlatformType, InstagramPositionsType, DevicePlatformType } from 'src/app/core/api-contracts';
import { ProfileService } from 'src/app/core/user/services/profile.service';
import { IPushNotification } from './../../../core/notification/interfaces/push-notification.interface';

@Component({
  selector: 'app-audiencedetail',
  templateUrl: './audiencedetail.component.html',
  styleUrls: ['./audiencedetail.component.css']
})
export class AudiencedetailComponent implements OnInit, OnDestroy {

  public audience: any;
  public targeting: any;
  public audienceSize: any;

  public profile: IFBUserProfile;
  private currentUserId: string = null;
  public facebook = false;
  public instagram = false;
  public showConfirmPic = false;

  // Age
  public ageParameters = {
    minAge: 16,
    maxAge: 64,
    ageSelectorNumbers: []
  };
  public ageSelectorType = AgeSelectorType;

  // Gender
  public genderType = GenderType;
  private fbGenders: number[] = [];

  // Targeting
  private interestsTargettingObject: IInterestOutput = {
    flexible_spec: [],
    exclusions: []
  };
  private includedLanguages: IFBLanguage[] = [];

  // Map
  locationOption: any = 'Include';
  optionStatus = true;
  address: any = {};
  location: any;
  excludelocation: any;
  locations: any;
  excludeLocations: any;
  radius: number;
  zoom: any;
  noResultsLocation: any;
  noResultsExcludeLocation: any;

  // Interest
  interests: any;
  excludeinterests: any;
  noResults: boolean;
  excludenoResults: boolean;
  excludeStatus = false;
  narrowStatus = false;
  interestStatus: any;
  narrowLayers: any;
  items: any = [];
  interestCount: any;

  // Language
  language: any;
  noResultsLanguage: any;

  // Ad objective type
  public availableAdObjectiveTypes = [
    AdObjectiveType.LINK_CLICKS,
    AdObjectiveType.PAGE_LIKES,
    AdObjectiveType.MESSAGES,
    AdObjectiveType.PRODUCT_CATALOG_SALES,
    AdObjectiveType.POST_ENGAGEMENT,
    AdObjectiveType.EVENT_RESPONSES
  ];
  public selectedAdObjective: AdObjectiveType = AdObjectiveType.LINK_CLICKS;

  // Call to action
  public availableCallToAction = [
    CallToActionType.BUY_NOW,
    CallToActionType.EVENT_RSVP,
    CallToActionType.LEARN_MORE
  ];
  public selectedCallToAction: CallToActionType = CallToActionType.BUY_NOW;

  public selectedSpecialAdCategory: SpecialAdCategory;

  // Budget
  public selectedBudget = 100; // in cents

  // Date
  public selectedStartDate = moment().format('YYYY-MM-DD'); // '2020-08-19';
  public selectedStartTime = '00:00:00'; // 'hh-mm-ss';

  public selectedEndDate = moment()
    .add(7, 'd')
    .format('YYYY-MM-DD'); // '2020-08-19'
  public selectedEndTime = '00:00:00'; // 'hh-mm-ss';

  // URL
  public selectedUrl: string;

  // Connections
  connections: any;
  connection: any;
  connectionOption: any = 'People who like your Page';
  showConnection = false;
  page: any = [];
  userID: any;
  pageName: any;
  pageNumber: any;

  // Request
  public audienceRequest: any;
  requestId: string;
  percentage: any = '';
  audienceName: any = '';

  public serverResponseStatus = '';

  private destroy$: Subject<void> = new Subject();

  constructor(
    private ref: ChangeDetectorRef,
    private firebaseService: FirebaseService,
    private transferService: TransferService,
    private afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    this.getAudienceDetails();
    this.initAudience();
    this.getPageInfo(this.userID);
    console.log('uuid', this.userID);

    // Initialize age selection
    for (
      let currNumber = this.ageParameters.minAge;
      currNumber < this.ageParameters.maxAge;
      currNumber++
    ) {
      this.ageParameters.ageSelectorNumbers.push(currNumber);
    }

  }

  public onSelectAdObjectiveType(newAdObjective: AdObjectiveType) {
    this.selectedAdObjective = newAdObjective;
  }

  public mapAdObjectiveTypeToText(adObjectiveType: AdObjectiveType): string {
    switch (adObjectiveType) {
      case AdObjectiveType.EVENT_RESPONSES:
        return 'Event responses';
      case AdObjectiveType.LEAD_GENERATION:
        return 'Lead generation';
      case AdObjectiveType.MESSAGES:
        return 'Messages';
      case AdObjectiveType.LINK_CLICKS:
        return 'Link clicks';
      case AdObjectiveType.PAGE_LIKES:
        return 'Page likes';
      case AdObjectiveType.PRODUCT_CATALOG_SALES:
        return 'Product catalog sales';
      case AdObjectiveType.POST_ENGAGEMENT:
        return 'Post engagement';
    }

    return 'Unknown ad objective';
  }

  // Targeting
  public onDetailedTargetingChange(newTargettingState) {
    this.interestsTargettingObject = newTargettingState;
  }

  public onSelectCallToAction(newCallToAction: CallToActionType) {
    this.selectedCallToAction = newCallToAction;
  }

  public mapCallToActionToText(mapToAction: CallToActionType): string {
    switch (mapToAction) {
      case CallToActionType.BUY_NOW:
        return 'Buy now';
      case CallToActionType.EVENT_RSVP:
        return 'Event response';
      case CallToActionType.LEARN_MORE:
        return 'Learn more';
    }

    return 'Unknown call to action';
  }

  public onSpecialAdCategoryChange(adCategory: SpecialAdCategory) {
    this.selectedSpecialAdCategory = adCategory;
  }

  // Age
  public onMinMaxAgeSelect(selectedAge: number, minOrMax: AgeSelectorType) {
    switch (minOrMax) {
      case AgeSelectorType.MaxAge:
        this.ageParameters.maxAge = selectedAge;
        break;
      case AgeSelectorType.MinAge:
        this.ageParameters.minAge = selectedAge;
        break;
    }

    if (this.ageParameters.minAge > this.ageParameters.maxAge) {
      this.ageParameters.minAge = this.ageParameters.maxAge;
    }
  }

  // Gender
  public onGenderSelectChanged(newSelectedGender: GenderType) {
    this.fbGenders = [];
    switch (newSelectedGender) {
      case GenderType.Male: {
        this.fbGenders.push(1);
        break;
      }
      case GenderType.Female: {
        this.fbGenders.push(2);
        break;
      }
      case GenderType.Both: {
        this.fbGenders.push(1);
        this.fbGenders.push(2);
        break;
      }
    }
  }

  // Audience
  initAudience() {
    this.audience = {
      // AllowedUsers:[],
      age_min: 13,
      age_max: 14,
      gender: 2,
      interests: [[]],
      exclusion: [],
      locations: [],
      excludeLocations: [],
      addresses: [],
      excludeaddresses: [],
      languages: [],
      connection: null,
      connectionPages: [],
      friendsconnectionPages: [],
      excludeconnectionPages: []
    };

    this.location = {
      distance_unit: 'kilometer',
      latitude: '',
      longitude: '',
      radius: '16',
      name: 'Calgary,Alberta,Canada',
      status: false,
      type: ''
    };

    this.excludelocation = {
      distance_unit: 'kilometer',
      latitude: 51.04473309999999,
      longitude: -114.07188309999998,
      radius: 16,
      name: 'Calgary,Alberta,Canada',
      status: false,
      type: ''
    };

    this.interestCount = 2;
    this.interests = { name: '', list: [] };
    this.language = { name: '', list: [] };
    this.locations = { name: '', list: [] };
    this.excludeLocations = { name: '', list: [] };
    this.excludeinterests = { name: '', list: [] };
    this.radius = 10;
    this.zoom = 9;
    this.narrowLayers = 0;
    this.connection = 'Add a connection type';
    this.connections = ['People who like your Page', 'Friends of people who like your Page', 'Exclude people who like your Page'];
    this.pageName = 'Please choose a page';

    this.targeting = {
      geo_locations: {
        custom_locations: []
      },
      excluded_geo_locations: {
        custom_locations: []
      },
      publisher_platforms: [
        'facebook', 'instagram', 'messenger', 'audience_network'
      ],
      age_max: 65,
      age_min: 13,
      genders: [0, 1],
      flexible_spec: [{}],
      exclusions: {},
      locales: [],
      connections: [],
      excluded_connections: [],
      friends_of_connections: []
    };
  }


  private getAudienceDetails() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        this.requestId = paramMap.get('id');
      }
      this.firebaseService.getAudienceDetails(this.requestId).pipe(takeUntil(this.destroy$)).subscribe(audienceRequest => {
        this.audienceRequest = audienceRequest;
        console.log('audienceRequest');
        console.log(this.audienceRequest);

        if ('facebookLink' in this.audienceRequest) {
          this.facebook = true;
        }
        if ('instagramLink' in this.audienceRequest) {
          this.instagram = true;
        }
      });
    });
  }


  gotoLink(option) {
    if (option === 'facebook') {
      window.open(this.audienceRequest.facebookLink, '_blank');
    }
    if (option === 'instagram') {
      window.open(this.audienceRequest.instagramLink, '_blank');
    }

  }



  public sendAudience() {
    this.serverResponseStatus = 'Sent...';
    // Generate exclusions for the facebook API
    let generatedExclusions = [];
    if (this.interestsTargettingObject && this.interestsTargettingObject.exclusions) {
      const exclusionKeys = Object.keys(this.interestsTargettingObject.exclusions);
      for (const objKey of exclusionKeys) {
        const arrItem = {};
        arrItem[objKey] = this.interestsTargettingObject.exclusions[objKey];

        generatedExclusions = [...generatedExclusions, arrItem];
      }
    }

    const generatedAudience: IAudience = {
      age_max: this.ageParameters.maxAge,
      age_min: this.ageParameters.minAge,
      createDate: new Date().toDateString(),
      geo_locations: {
        cities: this.targeting.geo_locations.cities,
        custom_locations: this.targeting.geo_locations.custom_locations
      },
      flexible_spec: this.interestsTargettingObject.flexible_spec,
      exclusions: generatedExclusions,
      genders: this.fbGenders,
      publisher_platforms: [
        PublisherPlatformType.Facebook,
        PublisherPlatformType.Instagram,
        PublisherPlatformType.Messenger
      ],
      facebook_positions: ['feed'],
      instagram_positions: [InstagramPositionsType.Stream, InstagramPositionsType.Explore],
      device_platforms: [DevicePlatformType.Mobile, DevicePlatformType.Desktop]
    };

    console.log('Generated Audience');
    console.log(generatedAudience);

    const createNewAudience: ICreateAudience = {
      requestId: this.audienceRequest.id,
      userId: this.audienceRequest.userId,
      pageId: this.audienceRequest.pageId,
      callToAction: this.selectedCallToAction,
      url: this.selectedUrl,
      objective: this.selectedAdObjective,
      audience: generatedAudience
    };

    const startDateParsed = `${this.selectedStartDate}T${this.selectedStartTime}+z`;
    const endDateParsed = `${this.selectedEndDate}T${this.selectedEndTime}+z`;

    console.log('Audience request object', createNewAudience);

    this.firebaseService
      .createAudienceForPage(createNewAudience)
      .pipe(
        take(1)
      )
      .subscribe((response: string) => {
        if (typeof response === 'string') {
          // send a push notification to the client
          // if the response was ok, then send a notification to the client phone:
          const pushNotification: IPushNotification = {
            users: [this.audienceRequest.userId],
            message: {
              notification: {
                title: 'Audience Delivered',
                body: 'Your new audience is ready to run. Tap to get started.'
              },
              apns: {
                payload: {
                  aps: {
                    'mutable-content': 1,
                    'content-available': 1,
                    sound: 'default',
                    destination: 'audience'
                  }
                }
              }
            },
            validateOnly: false
          };
          this.firebaseService.notify(pushNotification)
           .subscribe(console.log, console.log, console.log);
           
          this.serverResponseStatus = 'OK';
          this.resetForm();
        } else {
          this.serverResponseStatus = 'Error';
        }
      });
  }

  private resetForm() {
    this.ageParameters.minAge = 16;
    this.ageParameters.maxAge = this.ageParameters.ageSelectorNumbers.length;
    this.selectedAdObjective = AdObjectiveType.EVENT_RESPONSES;
    this.selectedCallToAction = CallToActionType.BUY_NOW;
    this.selectedUrl = null;
    this.selectedBudget = 100;
    this.selectedStartTime = '00:00:00';
    this.selectedStartDate = moment().format('YYYY-MM-DD');
    this.selectedEndDate = moment()
      .add(7, 'd')
      .format('YYYY-MM-DD');
    this.selectedEndTime = '00:00:00';

    this.profileService.resetSelectedInterests();
    this.profileService.resetSelectedLanguages();
    this.location = {
      distance_unit: 'kilometer',
      latitude: '',
      longitude: '',
      radius: '16',
      name: 'Calgary,Alberta,Canada',
      status: false,
      type: ''
    };

    this.excludelocation = {
      distance_unit: 'kilometer',
      latitude: 51.04473309999999,
      longitude: -114.07188309999998,
      radius: 16,
      name: 'Calgary,Alberta,Canada',
      status: false,
      type: ''
    };

    this.locations = { name: '', list: [] };
    this.radius = 10;
    this.zoom = 9;

    this.audience.locations = [];
    this.audience.addresses = [];
  }


  // Map

  getlocation(e) {
    if (e.target.value.length === 0) {

      this.locations.list = null;
      this.noResultsLocation = true;

      return;
    }

    this.firebaseService.searchLocation(e.target.value).subscribe((location: any) => {
      console.log(location);
      if (location.locations.length <= 0 || e.target.value === 0) {

        this.noResultsLocation = true;
        this.locations.list = null;

      } else {

        this.noResultsLocation = false;
        this.locations.list = location.locations;
      }

    });

  }




  addLocation(key, name, type, countryName, option) {



    if (type === 'zip') {
      this.location.name = name + ',' + countryName;
    } else {
      this.location.name = name;
    }


    if (option) {
      if (type === 'city') {
        this.audience.locations.push({ key, name, countryName, type, radius: '16', distance_unit: 'kilometer', status: false });
      } else {
        this.audience.locations.push({ key, name, countryName, type });
      }


      if (type === 'country') {
        this.targeting.geo_locations.countries.push(key);
      } else if (type === 'city') {
        if (!('cities' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.cities = [];
        }
        this.targeting.geo_locations.cities.push({ key, radius: '16', distance_unit: 'kilometer' });
      } else if (type === 'region') {
        if (!('regions' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.regions = [];
        }
        this.targeting.geo_locations.regions.push({ key });
      } else if (type === 'zip') {
        if (!('zips' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.zips = [];
        }
        this.targeting.geo_locations.zips.push({ key });
      } else if (type === 'geo_market') {
        if (!('geo_markets' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.geo_markets = [];
        }
        this.targeting.geo_locations.geo_markets.push({ key, name });
      } else if (type === 'electoral_district') {
        if (!('electoral_districts' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.electoral_districts = [];
        }
        this.targeting.geo_locations.electoral_districts.push({ key });
      } else if (type === 'country_group') {
        if (!('country_groups' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.country_groups = [];
        }
        this.targeting.geo_locations.country_groups.push(key);
      } else if (type === 'neighborhood') {
        if (!('neighborhoods' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.neighborhoods = [];
        }
        this.targeting.geo_locations.neighborhoods.push({ key, name });
      } else if (type === 'subcity') {
        if (!('subcities' in this.targeting.geo_locations)) {
          this.targeting.geo_locations.subcities = [];
        }
        this.targeting.geo_locations.subcities.push({ key, name });
      } else {

      }

    } else {
      if (type === 'city') {
        this.audience.excludeLocations.push({ key, name, countryName, type, radius: '16', distance_unit: 'kilometer', status: false });
      } else {
        this.audience.excludeLocations.push({ key, name, countryName, type });
      }



      if (type === 'country') {
        this.targeting.excluded_geo_locations.countries.push(key);
      } else if (type === 'city') {
        if (!('cities' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.cities = [];
        }
        this.targeting.excluded_geo_locations.cities.push({ key, radius: '16', distance_unit: 'kilometer' });
      } else if (type === 'region') {
        if (!('regions' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.regions = [];
        }
        this.targeting.excluded_geo_locations.regions.push({ key });
      } else if (type === 'zip') {
        if (!('zips' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.zips = [];
        }
        this.targeting.excluded_geo_locations.zips.push({ key });
      } else if (type === 'geo_market') {
        if (!('geo_markets' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.geo_markets = [];
        }
        this.targeting.excluded_geo_locations.geo_markets.push({ key, name });
      } else if (type === 'electoral_district') {
        if (!('electoral_districts' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.electoral_districts = [];
        }
        this.targeting.excluded_geo_locations.electoral_districts.push({ key });
      } else if (type === 'country_group') {
        if (!('country_groups' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.country_groups = [];
        }
        this.targeting.excluded_geo_locations.country_groups.push(key);
      } else if (type === 'neighborhood') {
        if (!('neighborhoods' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.neighborhoods = [];
        }
        this.targeting.excluded_geo_locations.neighborhoods.push({ key, name });
      } else if (type === 'subcity') {
        if (!('subcities' in this.targeting.excluded_geo_locations)) {
          this.targeting.excluded_geo_locations.subcities = [];
        }
        this.targeting.excluded_geo_locations.subcities.push({ key, name });
      } else {

      }
    }

    this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
      this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    });



    this.locations.list = null;
    this.locations.name = null;
    console.log(this.audience);
  }



  removeLocation(key, type) {
    let locationType;
    if (type === 'city') {
      locationType = 'cities';
    } else if (type === 'subcity') {
      locationType = 'subcities';
    } else {
      locationType = type + 's';
    }


    let locationId = this.audience.locations.map(res => res.key).indexOf(key);

    // const interestUid = <number>await this.searchInterestArr(id);
    if (locationId >= 0) {
      const locationId2 = this.targeting.geo_locations[locationType].map(res => res.key).indexOf(key);
      this.audience.locations.splice(locationId, 1);
      this.targeting.geo_locations[locationType].splice(locationId2, 1);
      this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    }

    locationId = this.audience.excludeLocations.map(res => res.key).indexOf(key);

    // const interestUid = <number>await this.searchInterestArr(id);
    if (locationId >= 0) {
      const locationId2 = this.targeting.excluded_geo_locations[locationType].map(res => res.key).indexOf(key);
      this.audience.excludeLocations.splice(locationId, 1);
      this.targeting.excluded_geo_locations[locationType].splice(locationId2, 1);
      this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    }

    this.ref.detectChanges();

  }




  placeChanged(place, option) {
    console.log(place);
    this.location.name = place.formatted_address;
    this.location.latitude = place.geometry.location.lat();
    this.location.longitude = place.geometry.location.lng();
    console.log(this.location);

    if (option) {
      this.audience.addresses.push(
        {
          name: this.location.name,
          latitude: this.location.latitude,
          longitude: this.location.longitude,
          radius: this.location.radius,
          distance_unit: this.location.distance_unit,
          status: this.location.status,
        });

      this.targeting.geo_locations.custom_locations.push(
        {
          latitude: this.location.latitude, longitude: this.location.longitude,
          radius: 16, distance_unit: 'kilometer'
        });
    } else {
      this.audience.excludeaddresses.push(
        {
          name: this.location.name,
          latitude: this.location.latitude,
          longitude: this.location.longitude,
          radius: this.location.radius,
          distance_unit: this.location.distance_unit,
          status: this.location.status,
        });

      this.targeting.excluded_geo_locations.custom_locations.push(
        { latitude: this.location.latitude, longitude: this.location.longitude, radius: 16, distance_unit: 'kilometer' });
    }
    this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
      this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    });



    this.ref.detectChanges();
  }



  removeAddress(locationName) {
    const locationId = this.audience.addresses.map(res => res.name).indexOf(locationName);

    if (locationId >= 0) {
      this.audience.addresses.splice(locationId, 1);
      this.targeting.geo_locations.custom_locations.splice(locationId, 1);
      this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return;
      });
    }

    const locationId2 = this.audience.excludeaddresses.map(res => res.name).indexOf(locationName);

    if (locationId2 >= 0) {
      this.audience.excludeaddresses.splice(locationId, 1);
      this.targeting.excluded_geo_locations.custom_locations.splice(locationId, 1);
      this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    }

    this.ref.detectChanges();

  }




  public selectChangeHandlerLocation(event: any) {
    this.locationOption = event.target.value;
    this.optionStatus = this.locationOption === 'Include';
  }



  onMapClick(event, option) {
    console.log(event);

    this.location.latitude = event.latLng.lat();
    this.location.longitude = event.latLng.lng();
    this.location.name = '(' + this.location.latitude + ',' + this.location.longitude + ')';

    if (option) {
      this.audience.addresses.push(
        {
          name: this.location.name,
          latitude: this.location.latitude,
          longitude: this.location.longitude,
          radius: this.location.radius,
          distance_unit: this.location.distance_unit,
          status: this.location.status,
        });

      this.targeting.geo_locations.custom_locations.push(
        { latitude: this.location.latitude, longitude: this.location.longitude, radius: 16, distance_unit: 'kilometer' });
    } else {
      this.audience.excludeaddresses.push(
        {
          name: this.location.name,
          latitude: this.location.latitude,
          longitude: this.location.longitude,
          radius: this.location.radius,
          distance_unit: this.location.distance_unit,
          status: this.location.status,
        });

      this.targeting.excluded_geo_locations.custom_locations.push(
        { latitude: this.location.latitude, longitude: this.location.longitude, radius: 16, distance_unit: 'kilometer' });
    }
    this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
      this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    });

    console.log(this.audience.addresses);



    this.ref.detectChanges();



    // event.target.panTo([event.latLng]);


  }


  showSlideBar(locationName, option) {
    if (option) {
      for (let i = 0; i < this.audience.addresses.length; i++) {

        if (this.audience.addresses[i].name === locationName) {
          this.audience.addresses[i].status = true;
          break;
        }
      }
    } else {
      for (let i = 0; i < this.audience.excludeaddresses.length; i++) {

        if (this.audience.excludeaddresses[i].name === locationName) {
          this.audience.excludeaddresses[i].status = true;
          break;
        }
      }
    }
    this.ref.detectChanges();
  }



  showSlideBarLocation(locationName, option) {
    if (option) {
      for (let i = 0; i < this.audience.locations.length; i++) {

        if (this.audience.locations[i].name === locationName) {
          this.audience.locations[i].status = true;
          break;
        }
      }
    } else {
      for (let i = 0; i < this.audience.excludeLocations.length; i++) {

        if (this.audience.excludeLocations[i].name === locationName) {
          this.audience.excludeLocations[i].status = true;
          break;
        }
      }
    }
    this.ref.detectChanges();
  }



  radiusChange(locationKey, option) {
    console.log(locationKey);
    if (this.radius / 10 >= 1 && this.radius / 10 < 2) {
      this.zoom = 9;
    } else if (this.radius / 10 >= 2 && this.radius / 10 < 3) {
      this.zoom = 8;
    } else {
      this.zoom = 7;
    }

    if (option) {
      // this.location.radius= this.radius
      for (let i = 0; i < this.audience.locations.length; i++) {

        if (this.audience.locations[i].key === locationKey) {
          this.audience.locations[i].radius = this.radius;
          this.audience.locations[i].status = false;
          console.log(this.targeting.geo_locations.cities);
          const locationId = this.targeting.geo_locations.cities.map(res => res.key).indexOf(locationKey);
          console.log(locationId);

          this.targeting.geo_locations.cities[locationId].radius = this.radius;


          this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
            this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          });
          break;
        }
      }
    } else {
      for (let i = 0; i < this.audience.excludeLocations.length; i++) {

        if (this.audience.excludeLocations[i].key === locationKey) {
          this.audience.excludeLocations[i].radius = this.radius;
          this.audience.excludeLocations[i].status = false;
          console.log(this.targeting.excluded_geo_locations.cities);
          const locationId = this.targeting.excluded_geo_locations.cities.map(res => res.key).indexOf(locationKey);
          console.log(locationId);

          this.targeting.excluded_geo_locations.cities[locationId].radius = this.radius;


          this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
            this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          });
          break;
        }
      }
    }

    this.ref.detectChanges();

  }





  addressRadiusChange(locationName, option) {

    if (this.radius / 10 >= 1 && this.radius / 10 < 2) {
      this.zoom = 9;
    } else if (this.radius / 10 >= 2 && this.radius / 10 < 3) {
      this.zoom = 8;
    } else {
      this.zoom = 7;
    }

    if (option) {
      // this.location.radius= this.radius
      for (let i = 0; i < this.audience.addresses.length; i++) {

        if (this.audience.addresses[i].name === locationName) {
          this.audience.addresses[i].radius = this.radius;
          this.audience.addresses[i].status = false;

          this.targeting.geo_locations.custom_locations[i].radius = this.radius;


          this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
            this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          });
          break;
        }
      }
    } else {
      for (let i = 0; i < this.audience.excludeaddresses.length; i++) {

        if (this.audience.excludeaddresses[i].name === locationName) {
          this.audience.excludeaddresses[i].radius = this.radius;
          this.audience.excludeaddresses[i].status = false;


          this.targeting.excluded_geo_locations.custom_locations[i].radius = this.radius;


          this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
            this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          });
          break;
        }
      }
    }

    this.ref.detectChanges();

  }

  // Gender
  addGender(gender) {
    this.audience.gender = gender;
    console.log(this.audience.gender);

    if (gender === 1) {
      this.targeting.genders = [];
      this.targeting.genders.push(this.audience.gender);
      console.log(this.targeting.genders);
      this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    } else if (gender === 2) {
      this.targeting.genders = [];
      this.targeting.genders.push(this.audience.gender);
      this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    } else {
      this.targeting.genders = [];
      this.targeting.genders.push(1, 2);
      this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    }
  }






  // Interests
  addInterest(id, name, type, layer) {

    console.log(layer);
    const interestId = this.audience.interests[layer].map(res => res.id).indexOf(id);


    if (interestId >= 0) {
      const interestId2 = this.targeting.flexible_spec[layer][type].map(res => res.id).indexOf(id);
      this.audience.interests[layer].splice(interestId, 1);
      this.targeting.flexible_spec[layer][type].splice(interestId2, 1);
      this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    } else {
      this.audience.interests[layer].push({ id, name, type });
      if (!(type in this.targeting.flexible_spec[layer])) {
        this.targeting.flexible_spec[layer][type] = [];
      }
      this.targeting.flexible_spec[layer][type].push({ id, name });
      this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    }
    this.interests.list = null;
    this.interests.name = null;


    console.log(this.audience);
  }




  addExcludeInterest(id, name, type) {


    const interestId = this.audience.exclusion.map(res => res.id).indexOf(id);


    if (interestId >= 0) {
      const interestId2 = this.targeting.exclusions[type].map(res => res.id).indexOf(id);
      this.audience.exclusion.splice(interestId, 1);
      this.targeting.exclusions[type].splice(interestId2, 1);
      this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    } else {

      this.audience.exclusion.push({ id, name, type });
      if (!(type in this.targeting.exclusions)) {
        this.targeting.exclusions[type] = [];
      }
      this.targeting.exclusions[type].push({ id, name });
      this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    }
    this.interests.list = null;
    this.interests.name = null;
    console.log(this.audience);
  }



  getInterest(e, status) {
    this.interestStatus = status;
    if (e.target.value.length === 0) {
      this.interests.list = null;
      this.noResults = true;
      return;
    }

    this.firebaseService.searchAudience(e.target.value).subscribe((interest: any) => {
      console.log(interest);
      if (interest.interests.length <= 0 || e.target.value === 0) {
        this.noResults = true;
        this.interests.list = null;
      } else {
        this.noResults = false;
        for (let i = 0; i < interest.interests.length; i++) {

          interest.interests[i].audience_size = interest.interests[i].audience_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        this.interests.list = interest.interests;
      }
    });
  }


  changeExcludeStatus() {
    this.excludeStatus = true;
  }


  changeNarrowStatus() {
    this.narrowStatus = true;
    this.audience.interests.push([]);
    this.targeting.flexible_spec.push({});
    this.interestCount++;
    this.items.push(this.interestCount);
  }












  // Language
  getLanguage(e) {
    if (e.target.value.length === 0) {
      this.language.list = null;
      this.noResultsLanguage = true;
      return;
    }

    this.firebaseService.searchLanguage(e.target.value).subscribe((locale: any) => {
      console.log(locale);
      if (locale.locales.length <= 0 || e.target.value === 0) {
        this.noResultsLanguage = true;
        this.language.list = null;
      } else {
        this.noResults = false;
        this.language.list = locale.locales;
      }
    });
  }



  addLanguage(key, name) {
    console.log(this.audience);

    const languageId = this.audience.languages.map(res => res.key).indexOf(key);

    // const interestUid = <number>await this.searchInterestArr2(id);
    if (languageId >= 0) {
      this.audience.languages.splice(languageId, 1);
      this.targeting.locales.splice(languageId, 1);
      this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    } else {

      this.audience.languages.push({ key, name });
      this.targeting.locales.push(key);
      this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    }
    this.language.list = null;
    this.language.name = null;
  }






  // Connections
  selectChangeHandler(event: any) {
    this.connection = event.target.value;
    this.audience.connection = this.connection;
    this.showConnection = true;
    this.connectionOption = this.connection;

    if (this.connectionOption === 'People who like your Page') {
      this.audience.friendsconnectionPages = [];
      this.audience.excludeconnectionPages = [];
      this.targeting.friends_of_connections = [];
      this.targeting.excluded_connections = [];
    } else if (this.connectionOption === 'Friends of people who like your Page') {
      this.audience.connectionPages = [];
      this.audience.excludeconnectionPages = [];
      this.targeting.connections = [];
      this.targeting.excluded_connections = [];
    } else {
      this.audience.connectionPages = [];
      this.audience.excludeconnectionPages = [];
      this.targeting.connections = [];
      this.targeting.excluded_connections = [];
    }

  }





  selectChangeHandlerPage(event: any) {
    this.pageName = event.target.value;
    const pageId = this.page.map(res => res.name).indexOf(this.pageName);

    if (this.connectionOption === 'People who like your Page') {

      const pageId2 = this.audience.connectionPages.map(res => res.name).indexOf(this.pageName);

      if (pageId2 < 0) {
        this.audience.connectionPages.push({ id: this.page[pageId].id, name: this.pageName });
        this.targeting.connections.push({ id: this.page[pageId].id });
      }
    } else if (this.connectionOption == 'Friends of people who like your Page') {
      const pageId2 = this.audience.friendsconnectionPages.map(res => res.name).indexOf(this.pageName);

      if (pageId2 < 0) {
        this.audience.friendsconnectionPages.push({ id: this.page[pageId].id, name: this.pageName });
        this.targeting.friends_of_connections.push({ id: this.page[pageId].id });
      }
    } else {
      const pageId2 = this.audience.excludeconnectionPages.map(res => res.name).indexOf(this.pageName);

      if (pageId2 < 0) {
        this.audience.excludeconnectionPages.push({ id: this.page[pageId].id, name: this.pageName });
        this.targeting.excluded_connections.push({ id: this.page[pageId].id });
      }
    }


    this.firebaseService.getReachEstimate(this.targeting).subscribe((estimate: any) => {
      this.audienceSize = estimate.estimate.users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    });

  }

  removePages(pageName) {
    const pageId = this.audience.connectionPages.map(res => res.name).indexOf(pageName);
    this.audience.connectionPages.splice(pageId, 1);
  }

  getPageInfo(uid) {
    this.firebaseService.getPageInfo(uid).subscribe((page: any) => {
      this.page = page.pages;
      this.pageNumber = page.pages.length;
      console.log(this.page);
    });

  }

  // END LOCATION METHODS

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
