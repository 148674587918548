import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { take, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFirestore } from 'angularfire2/firestore';
import { ICreateAudience, IGeneratedFields } from '../core/audience';
import { IFBQuickAd } from '../core/user';
import { IPushNotification } from './../core/notification/interfaces/push-notification.interface';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  private devEndpoint = 'https://us-central1-' + 'advoz-342ea' + '.cloudfunctions.net/api/v2/';
  private localEndpoint = 'http://127.0.0.1:5000/advoz-342ea/us-central1/api/v2/';
  private functionsEndpoint = 'https://us-central1-' + environment.firebase.projectId + '.cloudfunctions.net/api/v2/';

  constructor(
    private firebaseAuth: AngularFireAuth,
    private http: HttpClient,
    private db: AngularFirestore
  ) {}

  public signInRegular(email, password) {
    return this.firebaseAuth.auth.signInWithEmailAndPassword(email, password);
  }

  public getUserDetails(uid) {
    return this.authenticatedGet('getuserdetail', {
      userId: uid
    });
  }

  public getAllUsers(query, startDoc) {
    return this.authenticatedGet('getallusers', {
      query,
      startDoc
    });
  }

  public getIndustries() {
    return this.authenticatedGet('getindustriesD');
  }

  public addIndustry(industryName) {
    return this.authenticatedPost('putindustryD', {
      industryName
    });
  }

  public deleteIndustry(industryId) {
    return this.authenticatedPost('deleteindustry', {
      industryId
    });
  }

  unflagNewAccount(uid) {
    return this.db.collection('User').doc(uid).ref.set({ newUser: false }, { merge: true });
  }

  public getAudienceRequest(query, startDoc) {
    return this.authenticatedGet('getallaudiencerequests', {
      query,
      startDoc
    });
  }

  public getContentRequest(query, startDoc) {
    return this.authenticatedGet('getallcontentrequests', {
      query,
      startDoc
    });
  }

  public getCopyRequest(query, startDoc) {
    return this.authenticatedGet('getallcopyrequests', {
      query,
      startDoc
    });
  }

  public getInfluencerRequest(query, startDoc) {
    return this.authenticatedGet('getallinfluencerrequests', {
      query,
      startDoc
    });
  }

  public getCampaignRequest(query, startDoc) {
    return this.authenticatedGet('getallcampaignrequests', {
      query,
      startDoc
    });
  }



  public getAudienceDetails(requestId) {
    return this.authenticatedGet('getaudiencerequestdetail', {
      audienceRequestId: requestId
    });
  }

  public getContentDetails(requestId) {
    return this.authenticatedGet('getcontentrequestdetail', {
      contentRequestId: requestId
    });
  }


  public getCopyDetails(requestId) {
    return this.authenticatedGet('getcopyrequestdetail', {
      copyRequestId: requestId
    });
  }

  public getInfluencerDetails(requestId) {
    return this.authenticatedGet('getinfluencerrequestdetail', {
      influencerRequestId: requestId
    });
  }

  public getCampaignDetails(requestId) {
    return this.authenticatedGet('getcampaignrequestdetail', {
      campaignRequestId: requestId
    });
  }

  public sendAudience(audience, requestId, userId, percentage, audienceName, generatedFields: IGeneratedFields = null) {
    audience.objective = 'My new objective';
    return this.authenticatedPost('makeaudienceforpage', {
      requestId,
      userId,
      audience,
      ...generatedFields,
      percentage,
      audienceName
    });
  }
  
  public createAudienceForPage(generatedAudience: ICreateAudience) {
    return this.authenticatedPost('makeaudienceforpage', {...generatedAudience});
  }

  public createStandAloneAudienceForUser(generatedAudience: ICreateAudience) {
    return this.authenticatedPost('createstandaloneaudienceforuser', {...generatedAudience});
  }

  public postQuickAd(generatedQuickAd: IFBQuickAd) {
    return this.authenticatedPost('postquickad', {...generatedQuickAd});
  }

  public notify(pushNotification: IPushNotification) {
    return this.authenticatedPost('notify', {...pushNotification});
  }



  public sendContent(requestId, userId, imageURL) {
    return this.authenticatedPost('makecontentforpage', {
      requestId,
      userId,
      links: imageURL
    });
  }


  sendCopy(requestId, userId, primaryText, headline, description) {
    return this.authenticatedPost('makecopyforpage', {
      requestId,
      userId,
      primaryText,
      headline,
      description
    });
  }


  sendInfluencer(requestId, userId, influencer) {
    return this.authenticatedPost('makeinfluencerforpage', {
      requestId,
      userId,
      influencer
    });
  }



  public getAllPost(userId, pageCursors) {
    return this.authenticatedGet('getallpostsD', {
      userId,
      pageCursors
    });
  }




  public getAllEvent(userId, pageCursors) {
    return this.authenticatedGet('getalleventsD', {
      userId,
      pageCursors
    });
  }

  public getAllCatalog(businessId, fbToken) {
    return this.authenticatedGet('getcatalogsD', {
      businessId,
      fbToken
    });
  }

  public getAllProductSet(catalogId, fbToken) {
    return this.authenticatedGet('getproductsetsD', {
      catalogId,
      fbToken
    });
  }

  public getAllCproducts(catalogId, fbToken) {
    return this.authenticatedGet('getcatalogproductsD', {
      catalogId,
      fbToken
    });
  }

  public getAllproducts(productsetId, fbToken) {
    return this.authenticatedGet('getcatalogproductsD', {
      productsetId,
      fbToken
    });
  }

  public searchAudience(interest) {
    return this.authenticatedGet('getinterests', {
      condition: interest
    });
  }

  public searchLocation(location) {
    return this.authenticatedGet('getlocations', {
      condition: location
    });
  }

  public searchLocation2(location) {
    let url = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=' +
              location + '&key=AIzaSyCTsP0aYVCr7TbjqYmDob_NmfpO-SSWcs4';
    const proxyurl = 'https://cors-anywhere.herokuapp.com/';
    url = proxyurl + url;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': `${authorization}`
      })
    };
    return this.http.get(url, httpOptions);
  }


  public searchLanguage(locale) {
    return this.authenticatedGet('getlocales', {
      condition: locale
    });
  }

  public getReachEstimate(targeting) {
    return this.authenticatedGet('getreachestimate', {
      targeting: JSON.stringify(targeting)
    });
  }


  public getPageInfo(uid) {
    return this.authenticatedGet('getpagesdashboard', {
      userId: uid
    });
  }

  // Get data from endpoint
  public authenticatedGet(endpoint: string, queryParametters: any = {}) {
    // Create a query string based on object
    const queryString = this.objectToQueryString(queryParametters);

    return this.firebaseAuth.idToken.pipe(
      take(1),
      switchMap((idToken) => {
        const url = this.functionsEndpoint + endpoint + '?' + queryString;
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
          })
        };
        return this.http.get(url, httpOptions);
      })
    );
  }

  // post data from endpoint
  public authenticatedPost(endpoint: string, body: any, queryParametters: any = {}) {
    // Create a query string based on object
    const queryString = this.objectToQueryString(queryParametters);

    return this.firebaseAuth.idToken.pipe(
      take(1),
      switchMap((idToken) => {
        const url = this.functionsEndpoint + endpoint + '?' + queryString;
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
          })
        };
        return this.http.post(url, body, httpOptions);
      })
    );
  }

  private objectToQueryString(queryObject: any): string {
    return Object.keys(queryObject).map(key => {
      if (queryObject[key]) {
        return key + '=' + queryObject[key];
      } else {
        return undefined;
      }
    }).join('&');
  }

  public getFirebaseFunction(endpoint: string, query: any) {
    const authorization = 'Bearer ' + 'null';
    let url = this.functionsEndpoint;
    url += endpoint;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `${authorization}`
      })
    };


    return this.http.get(url, httpOptions);
  }

  public postFirebaseFunction(endpoint: string, body: any) {
    const authorization = 'Bearer ' + 'null';
    let url = this.functionsEndpoint;
    url += endpoint;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `${authorization}`
      })
    };

    return this.http.post(url, body, httpOptions);
  }

  public getFirebaseFunctionURL() {
    return this.functionsEndpoint;
  }
}
