import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs';


@Injectable()
export class AuthService {

  
  public authState: firebase.auth.UserCredential = null;

  private user: firebase.User;

  constructor(
    private angularFireAuth: AngularFireAuth,
    private router: Router
  ) {
    this.angularFireAuth.authState.subscribe((auth) => {
      this.user = auth;
    });
  }

  public loginWithEmail(email: string, password: string): Observable<firebase.auth.UserCredential> {
    this.angularFireAuth.auth.setPersistence('local');
    return new Observable((subscriber) => {
      this.angularFireAuth.auth.signInWithEmailAndPassword(email, password)
        .then((userCrendetials: firebase.auth.UserCredential) => {
          this.authState = userCrendetials;
          subscriber.next(userCrendetials);
          subscriber.complete();
        })
        .catch(error => {
          subscriber.error(error);
          subscriber.complete();
        });
    });
  }

  public signOut(): void {
    this.angularFireAuth.auth.signOut();
    this.router.navigate(['/login'])
  }
}
