import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs';
import { tap, take, map } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AngularFireAuth,
    private router: Router
  ) { }

  canActivate(): Observable<boolean> {
    return from(this.auth.authState)
      .pipe(take(1))
      .pipe(map(user => {
        if (user) {
          return true;
        } else {
          return false;
        }
      }))
      .pipe(tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['/login'])
        };
      }))
  }
}