import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SpecialAdCategory } from 'src/app/core/audience';

@Component({
  selector: 'app-special-ad-selector',
  templateUrl: './special-ad-selector.component.html',
  styleUrls: ['./special-ad-selector.component.sass']
})
export class SpecialAdSelectorComponent implements OnInit {
  @Output()
  public onChange: EventEmitter<SpecialAdCategory> = new EventEmitter();

  public availableAdCategories = [
    SpecialAdCategory.NONE,
    SpecialAdCategory.CREDIT,
    SpecialAdCategory.HOUSING,
    SpecialAdCategory.EMPLOYMENT
  ];
  public currentlySelectedAdCategory: SpecialAdCategory = SpecialAdCategory.NONE;

  constructor() {}

  ngOnInit() {}

  public mapAdCategoryToText(adCategory: SpecialAdCategory) {
    switch (adCategory) {
      case SpecialAdCategory.CREDIT:
        return 'Credit';
      case SpecialAdCategory.HOUSING:
        return 'Housing';
      case SpecialAdCategory.NONE:
        return 'None';
      case SpecialAdCategory.EMPLOYMENT:
        return 'Employment';
    }

    return 'Unkown';
  }

  public onSelectedSpecialAdCategory(adCategory: SpecialAdCategory) {
    this.currentlySelectedAdCategory = adCategory;
    this.onChange.emit(adCategory);
  }
}
