import { Injectable } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public resetSelectedIntrests$: Observable<any>;
  private _resetSelectedIntrests = new Subject();

  public resetSelectedLangauges$: Observable<any>;
  private _resetSelectedLangauges = new Subject();

  constructor(private firebaseService: FirebaseService) {
    this.resetSelectedIntrests$ = this._resetSelectedIntrests.asObservable();
    this.resetSelectedLangauges$ = this._resetSelectedLangauges.asObservable();
  }

  // This is the userid being used in QuickAds
  public currentActiveUserID = null;

  public setCurrentActiveUserID(id: string) {
    this.currentActiveUserID = id;
  }

  public getCurrentUserInfo() {
    return this.firebaseService.getUserDetails(this.currentActiveUserID);
  }

  public resetSelectedLanguages() {
    this._resetSelectedLangauges.next();
  }

  public resetSelectedInterests() {
    this._resetSelectedIntrests.next();
  }
}
