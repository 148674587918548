import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { IFBInterest } from 'src/app/core/user';
import { IInterestOutput } from 'src/app/core/audience';
import { ProfileService } from 'src/app/core/user/services/profile.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-interests-picker',
  templateUrl: './interests-picker.component.html',
  styleUrls: ['./interests-picker.component.scss']
})
export class InterestsPickerComponent implements OnInit, OnDestroy {
  @Output()
  public change: EventEmitter<IInterestOutput> = new EventEmitter();

  public selectedInterests: IFBInterest[][] = [[]];
  public selectedExclusions: IFBInterest[] = [];

  public loadedInterests: { name: string; list: IFBInterest[] } = { name: '', list: [] };

  public interestStatus = 0;
  public noResultsFound = true;

  // This is the object to be included in the audience for facebook
  public targetingOutput: any = {
    flexible_spec: [{}],
    exclusions: []
  };

  public currentExcludeStatus = false;
  public currentNarrowStatus = false;
  public narrowLayers = 0;
  public narrowElements: any = [];
  public interestElementCount = 2;

  public audienceSize = 0;

  private cancelPreviousLookup$: Subject<void> = new Subject();
  private destroy$: Subject<void> = new Subject();

  constructor(private firebaseService: FirebaseService, private profileService: ProfileService) {}

  ngOnInit() {
    this.subToResetSelection();
  }

  public onGetInterest(e, status) {
    this.cancelPreviousLookup$.next();
    this.interestStatus = status;
    if (e.target.value.length === 0) {
      this.loadedInterests.list = null;
      this.noResultsFound = true;
      return;
    }

    this.firebaseService.searchAudience(e.target.value).pipe(
      takeUntil(this.cancelPreviousLookup$),
      takeUntil(this.destroy$)
    ).subscribe((interest: any) => {
      if (interest.interests.length <= 0 || e.target.value === 0) {
        this.noResultsFound = true;
        this.loadedInterests.list = null;
      } else {
        this.noResultsFound = false;
        for (let i = 0; i < interest.interests.length; i++) {
          interest.interests[i].audience_size = interest.interests[i].audience_size
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
        this.loadedInterests.list = interest.interests;
      }
    });

    console.log('TARGETTING', this.targetingOutput.exclusions);
  }

  public changeExcludeStatus() {
    this.currentExcludeStatus = true;
  }

  public changeNarrowStatus() {
    this.currentNarrowStatus = true;
    this.selectedInterests.push([]);
    this.targetingOutput.flexible_spec.push({});
    this.interestElementCount++;
    this.narrowElements.push(this.interestElementCount);
  }

  // Include
  public addInterest(id, name, type, layer) {
    const interestId = this.selectedInterests[layer].map((res) => res.id).indexOf(id);

    if (interestId >= 0) {
      const interestId2 = this.targetingOutput.flexible_spec[layer][type]
        .map((res) => res.id)
        .indexOf(id);
      this.selectedInterests[layer].splice(interestId, 1);
      this.targetingOutput.flexible_spec[layer][type].splice(interestId2, 1);
    } else {
      this.selectedInterests[layer].push({ id, name, type, audience_size: 0 });
      if (!(type in this.targetingOutput.flexible_spec[layer])) {
        this.targetingOutput.flexible_spec[layer][type] = [];
      }
      this.targetingOutput.flexible_spec[layer][type].push({ id, name });
    }
    this.loadedInterests.list = null;
    this.loadedInterests.name = null;

    this.change.next(this.targetingOutput);
  }

  // Exclude
  public addExcludeInterest(id, name, type) {
    const interestId = this.selectedExclusions.map((res) => res.id).indexOf(id);

    if (interestId >= 0) {
      const interestId2 = this.targetingOutput.exclusions[type].map((res) => res.id).indexOf(id);
      this.selectedExclusions.splice(interestId, 1);
      this.targetingOutput.exclusions[type].splice(interestId2, 1);
      this.firebaseService.getReachEstimate(this.targetingOutput).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    } else {
      this.selectedExclusions.push({ id, name, type, audience_size: 0 });
      if (!(type in this.targetingOutput.exclusions)) {
        this.targetingOutput.exclusions[type] = [];
      }
      this.targetingOutput.exclusions[type].push({ id, name });
      this.firebaseService.getReachEstimate(this.targetingOutput).subscribe((estimate: any) => {
        this.audienceSize = estimate.estimate.users
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      });
    }
    this.loadedInterests.list = null;
    this.loadedInterests.name = null;

    this.change.next(this.targetingOutput);
  }

  private subToResetSelection() {
    this.profileService.resetSelectedIntrests$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.selectedExclusions = [];
      this.selectedInterests = [[]];
      this.noResultsFound = true;
      this.loadedInterests.list = null;
    })
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
