import { IFbUserApi } from '..';

export class User {
  public userId: string;
  public username: string;
  public businessPageName: string;
  public industry: string;
  public createdDate: number;
  public lastConnection: number;
  public adPackagesLeft: number;

  constructor(data: IFbUserApi) {
    this.userId = data.userId;
    this.username = data.name || '';
    this.businessPageName = data.activePageName || '';
    this.industry = data.activeIndustryName || '';
    data.createDate ? this.createdDate = data.createDate._seconds * 1000 : this.createdDate = 0;
    data.lastDate ? this.lastConnection = data.lastDate._seconds * 1000 : this.lastConnection = 0;
    this.adPackagesLeft = data.adPackagesLeft;
  }
}
