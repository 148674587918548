import { Component, ChangeDetectorRef,OnInit } from '@angular/core';
import { FirebaseService } from '../../../services/firebase.service';
import { AngularFireAuthModule, AngularFireAuth } from 'angularfire2/auth';
import { DeprecatedDatePipe } from '@angular/common';
import { TransferService } from '../../../services/transfer.service';

@Component({
  selector: 'app-campaigndetail',
  templateUrl: './campaigndetail.component.html',
  styleUrls: ['./campaigndetail.component.scss']
})
export class CampaigndetailComponent implements OnInit {

  engagementStatus:boolean=false;
  
  audience: any;
  targeting: any;
  audienceSize: any;
  calltoAction:any = "Learn More";
  calltoActions:any;
  isHovering: any;

  //Copy
  primaryText:any;
  headline:any;
  description:any;
  duration: any;
  budget: any;
  //Age
  counts=[]; 
  ageMin:any;
  ageMax:any;


  
  //Map
  locationOption:any="Include"
  optionStatus:boolean=true
  address: any = {};
  location:any;
  excludelocation:any;
  locations:any;
  excludelocations:any;
  radius:number
  zoom:any
  noResultsLocation:any;
  noResultsExcludeLocation:any;
  
  //Interest
  interests:any;
  excludeinterests:any;
  noResults: boolean;
  excludenoResults:boolean;
  excludeStatus:boolean =false;
  narrowStatus:boolean =false;
  interestStatus:any;
  narrowLayers:any;
  items:any=[];
  interestCount:any;

  //Language
  language:any;
  noResultsLanguage:any;
 
  //Connections
  connections:any;
  connection:any;
  connectionOption:any="People who like your Page"
  showConnection:boolean=false;
  page:any=[];
  userID:any;
  pageName:any;
  pageNumber:any;
  
  

  //Request
  request:any
  post:any
  nextPost:any
  pageCursors:any
  showPost:boolean=false
  event:any
  nextEvent:any
  showEvent:boolean=false
  facebook:boolean=false
  instagram:boolean=false
  showConfirmPic:boolean=false
  percentage:any=""
  audienceName:any=""
  ShowCalltoAction: boolean=true
  ShowMessages:boolean=false
  ShowCatalog:boolean = false
  ShowSet:boolean = false
  messengers:any = [""]
  catalog:any = "select a catalog"
  catalogs:any
  productset:any = "select a product set"
  productsets:any
  ShowProducts:any=false
  

  ShowCatalogProducts:boolean = false
  cProduct:any
  product:any

  toggle1 = true;
  toggle2 = true;
  toggle3 = true;
  toggle4 = true;
  objective:any = "Engagement"



  constructor(
    private ref: ChangeDetectorRef,
    private fbservice: FirebaseService,
    private transferService:TransferService,
    private afAuth: AngularFireAuth){
      
     }

  ngOnInit() {
    this.getCampaignDetails()
    this.count()
    console.log(this.counts)
    this.initAudience()
    this.userID="SXt44KYRnTozxlG5TVJb"
    this.getPageInfo(this.userID)

  }

addQuestion(){
  this.messengers.push("");
}
 
public startUpload(event: any) {

}
public toggleHover(event: any) {}

enableDisableRule(option) {
  if(option==1){
    this.toggle1 = false;
    this.toggle2 = true;
    this.toggle3 = true;
    this.toggle4 = true;
    this.ShowCalltoAction = true;
    this.ShowMessages = false;
    this.ShowCatalog = false;
    this.showEvent =false;
    this.showPost = false;
  }


else if(option==2){
    this.toggle1 = true;
    this.toggle2 = false;
    this.toggle3 = true;
    this.toggle4 = true;
    this.ShowCalltoAction = false;
    this.ShowMessages = true;
    this.ShowCatalog = false;
    this.showEvent =false;
    this.showPost = false;
  }

else if(option==3){
    this.toggle1 = true;
    this.toggle2 = true;
    this.toggle3 = false;
    this.toggle4 = true;
    this.ShowCalltoAction = true;
    this.ShowMessages = false;
    this.ShowCatalog = true;
    this.showEvent =false;
    this.showPost = false;
    this.getCatalog()

  }

else if(option==4){
    this.toggle1 = true;
    this.toggle2 = true;
    this.toggle3 = true;
    this.toggle4 = false;
    this.ShowCalltoAction = true;
    this.ShowMessages = false;
    this.ShowCatalog = false;
  }
  
}


getCampaignDetails(){
    this.request = this.transferService.getData2();
    console.log(this.request)
    if("facebookLink" in this.request)
           this.facebook = true;
    if("instagramLink" in this.request)
           this.instagram = true;
}


gotoLink(option){
  if(option=="facebook"){
    window.open(this.request.facebookLink, "_blank");
  }
  if(option=="instagram"){
    window.open(this.request.instagramLink, "_blank");
  }

}


getCatalog(){
  this.fbservice.getAllCatalog(this.request.activeBusinessId,this.request.fbToken).subscribe( result=>{
        console.log(result)
        this.catalogs = result
        this.catalogs = this.catalogs.data

  })
}


selectcatalog(event: any,catalogId){
  this.catalog = event.target.value;
  this.ShowCatalogProducts = false;
  this.getProductSet(catalogId)
  

}


getProductSet(catalogId){
  this.fbservice.getAllProductSet(catalogId,this.request.fbToken).subscribe( result=>{
        console.log(result)
        this.productsets = result
        this.productsets = this.productsets.product_sets.data
        this.ShowSet = true;
  })
}

selectproductset(event:any){
  this.productset = event.target.value;
  this.ShowProducts = false;
}

showCatalogProducts(catalogId){
  this.fbservice.getAllCproducts(catalogId,this.request.fbToken).subscribe( result=>{
    console.log(result)
    this.cProduct = result
    this.pageCursors = this.cProduct.cursors
    this.ShowCatalogProducts = true;
})
  
}


showProducts(productsetId){
  this.fbservice.getAllproducts(productsetId,this.request.fbToken).subscribe( result=>{
    console.log(result)
    this.product = result
    this.pageCursors = this.product.cursors
    this.ShowProducts = true;
})
  
}


getPost(event: any){
  this.enableDisableRule(4)
  this.objective = event.target.value;
  this.fbservice.getAllPost(this.request.userId,"").subscribe( result=>{
    console.log(result)

    this.post = result
    for(let i = 0;i<this.post.posts.length;i++){
      if(!("shares" in this.post.posts[i]))
         this.post.posts[i].shares={count:0}         
    }

    this.pageCursors = this.post.cursors
    
  //  console.log(JSON.stringify(this.post.cursors))

    this.showPost=true
    

  })
}



loadAdditionalPosts(){
  this.fbservice.getAllPost(this.request.userId, JSON.stringify(this.pageCursors)).subscribe( res => {
    this.nextPost = res
    for(let i = 0;i<this.nextPost.posts.length;i++){
      if(!("shares" in this.nextPost.posts[i]))
         this.nextPost.posts[i].shares={count:0}         
    }
    this.pageCursors = this.nextPost.cursors
    this.post.posts = this.post.posts.concat(this.nextPost.posts)
    console.log(this.post.posts)   
  
  })
}

scrollPostTable(event){
  if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    console.log("End");
     this.loadAdditionalPosts();
  }
}


getEvent(event: any){
  this.enableDisableRule(4)
  this.objective = event.target.value;
  this.fbservice.getAllEvent(this.request.userId,"").subscribe( result=>{
    console.log(result)

    this.event = result

    this.pageCursors = this.event.cursors
    
  //  console.log(JSON.stringify(this.post.cursors))

    this.showEvent=true
    
  })
}


loadAdditionalEvents(){
  this.fbservice.getAllEvent(this.request.userId, JSON.stringify(this.pageCursors)).subscribe( res => {
    this.nextEvent = res
    this.pageCursors = this.nextEvent.cursors
    this.event.events = this.event.events.concat(this.nextEvent.events)
    console.log(this.event.events)   
  
  })
}

scrollEventTable(event){
  if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    console.log("End");
     this.loadAdditionalEvents();
  }
}


sendCampaign(){
  this.fbservice.sendAudience(this.targeting,this.request.id,this.request.userId,this.percentage, this.audienceName).subscribe( detail=>{
    console.log(detail)
    this.showConfirmPic=true;
  
   
}
)
}


  initAudience(){
    this.audience = {
      //AllowedUsers:[],
      age_min:13,
      age_max:14,
      gender:2,
      interests:[[]],
      exclusion:[],
      locations:[],
      excludelocations:[],
      addresses:[],
      excludeaddresses:[],
      languages:[],
      connection:null,
      connectionPages:[],
      friendsconnectionPages:[],
      excludeconnectionPages:[]
    }
  
    this.location={
      distance_unit:"kilometer",
      latitude:"",
      longitude:"",
      radius:"16",
      name:"Calgary,Alberta,Canada",
      status:false,
      type:""
    }
  
    this.excludelocation={
      distance_unit:'kilometer',
      latitude:51.04473309999999,
      longitude:-114.07188309999998,
      radius:16,
      name:'Calgary,Alberta,Canada',
      status:false,
      type:""
    }
    
  
    this.ageMin=13;
    this.ageMax=65;
    this.interestCount=2;
    this.interests = {name:'', list:[]}
    this.language = {name:'', list:[]}
    this.locations = {name:'', list:[]}
    this.excludelocations = {name:'', list:[]}
    this.excludeinterests = {name:'', list:[]}
    this.radius=10;
    this.zoom=9;
    this.narrowLayers=0;
    this.connection="Add a connection type"
    this.connections=["People who like your Page","Friends of people who like your Page","Exclude people who like your Page"];
    this.calltoActions=["No Button", "Shop Now", 
    "Sign Up","Subscribe", "Watch More", "Listen Now", 
    "Apply Now", "Book Now", "Contact Us","Download",
    "Get Offer","Get Quote", "Get Showtimes","Learn More",
    "Request Time", "See Menu"]
    this.pageName="Please choose a page"
  
    this.targeting={
      geo_locations: {
        custom_locations:[]
      },
      excluded_geo_locations: {
        custom_locations:[]
      },
      publisher_platforms: [
        "facebook","instagram", "messenger", "audience_network"
      ],
     age_max:65,
     age_min:13,
     genders:[0,1],
     flexible_spec:[{}],
     exclusions:{},
     locales:[],
     connections:[],
     excluded_connections:[],
     friends_of_connections:[]
    }
  }


//Call tto Action
selectChangeHandlerCall(event){
  this.calltoAction = event.target.value;
}


//Placement
checkValue(event: any,option){
  if(event.currentTarget.checked){
    this.targeting.publisher_platforms.push(option)
    this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
      this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    })
  }
  else {
    let placementId = this.targeting.publisher_platforms.indexOf(option)
          this.targeting.publisher_platforms.splice(placementId, 1)
          this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
            this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          })
  }
      
  console.log(this.targeting);
}



//Map

getlocation(e){
  if(e.target.value.length == 0){
    
    this.locations['list'] = null
    this.noResultsLocation = true
    
    return
  }

  this.fbservice.searchLocation(e.target.value).subscribe( location =>{
    console.log(location)
      if(location['locations'].length <= 0 || e.target.value == 0){
        
        this.noResultsLocation = true
        this.locations['list'] = null
       
      }else{
        
        this.noResultsLocation = false
        this.locations['list'] = location['locations']}
       
    })
    
}




addLocation(key, name,type,countryName,option) {


      
      if(type=="zip"){
        this.location.name=name+","+countryName
      }
      else
        this.location.name=name
      

if(option){
      if(type=="city")
        this.audience.locations.push({key:key, name:name,countryName:countryName,type:type,radius:"16", distance_unit:"kilometer",status:false})
      else
        this.audience.locations.push({key:key, name:name,countryName:countryName,type:type})

      
      if(type=="country"){
        this.targeting.geo_locations["countries"].push(key)
      }
      else if(type=="city"){
        if(!("cities" in this.targeting.geo_locations))
         this.targeting.geo_locations["cities"]=[]
        this.targeting.geo_locations["cities"].push({key: key, radius:"16",distance_unit:"kilometer"})
      }
      else if(type=="region"){
        if(!("regions" in this.targeting.geo_locations))
         this.targeting.geo_locations["regions"]=[]
        this.targeting.geo_locations["regions"].push({key: key})
      }
      else if(type=="zip"){
        if(!("zips" in this.targeting.geo_locations))
         this.targeting.geo_locations["zips"]=[]
        this.targeting.geo_locations["zips"].push({key: key})
      }
      else if(type=="geo_market"){
        if(!("geo_markets" in this.targeting.geo_locations))
         this.targeting.geo_locations["geo_markets"]=[]
        this.targeting.geo_locations["geo_markets"].push({key: key,name:name})
      }
      else if(type=="electoral_district"){
        if(!("electoral_districts" in this.targeting.geo_locations))
         this.targeting.geo_locations["electoral_districts"]=[]
        this.targeting.geo_locations["electoral_districts"].push({key: key})
      }
      else if(type=="country_group"){
        if(!("country_groups" in this.targeting.geo_locations))
         this.targeting.geo_locations["country_groups"]=[]
        this.targeting.geo_locations["country_groups"].push(key)
      }
      else if(type=="neighborhood"){
        if(!("neighborhoods" in this.targeting.geo_locations))
         this.targeting.geo_locations["neighborhoods"]=[]
        this.targeting.geo_locations["neighborhoods"].push({key: key, name: name})
      }
      else if(type=="subcity"){
        if(!("subcities" in this.targeting.geo_locations))
         this.targeting.geo_locations["subcities"]=[]
        this.targeting.geo_locations["subcities"].push({key: key, name: name})
      }
      
     else{
          
      } 
  
    }


else{
if(type=="city")
  this.audience.excludelocations.push({key:key, name:name,countryName:countryName,type:type,radius:"16", distance_unit:"kilometer",status:false})
else
  this.audience.excludelocations.push({key:key, name:name,countryName:countryName,type:type})
         
  
          
          if(type=="country"){
            this.targeting.excluded_geo_locations["countries"].push(key)
          }
          else if(type=="city"){
            if(!("cities" in this.targeting.excluded_geo_locations))
             this.targeting.excluded_geo_locations["cities"]=[]
            this.targeting.excluded_geo_locations["cities"].push({key: key, radius:"16",distance_unit:"kilometer"})
          }
          else if(type=="region"){
            if(!("regions" in this.targeting.excluded_geo_locations))
             this.targeting.excluded_geo_locations["regions"]=[]
            this.targeting.excluded_geo_locations["regions"].push({key: key})
          }
          else if(type=="zip"){
            if(!("zips" in this.targeting.excluded_geo_locations))
             this.targeting.excluded_geo_locations["zips"]=[]
            this.targeting.excluded_geo_locations["zips"].push({key: key})
          }
          else if(type=="geo_market"){
            if(!("geo_markets" in this.targeting.excluded_geo_locations))
             this.targeting.excluded_geo_locations["geo_markets"]=[]
            this.targeting.excluded_geo_locations["geo_markets"].push({key: key,name:name})
          }
          else if(type=="electoral_district"){
            if(!("electoral_districts" in this.targeting.excluded_geo_locations))
             this.targeting.excluded_geo_locations["electoral_districts"]=[]
            this.targeting.excluded_geo_locations["electoral_districts"].push({key: key})
          }
          else if(type=="country_group"){
            if(!("country_groups" in this.targeting.excluded_geo_locations))
             this.targeting.excluded_geo_locations["country_groups"]=[]
            this.targeting.excluded_geo_locations["country_groups"].push(key)
          }
          else if(type=="neighborhood"){
            if(!("neighborhoods" in this.targeting.excluded_geo_locations))
             this.targeting.excluded_geo_locations["neighborhoods"]=[]
            this.targeting.excluded_geo_locations["neighborhoods"].push({key: key, name: name})
          }
          else if(type=="subcity"){
            if(!("subcities" in this.targeting.excluded_geo_locations))
             this.targeting.excluded_geo_locations["subcities"]=[]
            this.targeting.excluded_geo_locations["subcities"].push({key: key, name: name})
          }
          
         else{
              
          }
        }
      
    this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
        this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}) 
      
      
    
    this.locations['list']=null
    this.locations['name']=null
    console.log(this.audience)
  }



  removeLocation(key,type){
    var locationType
    if(type=="city")
         locationType= "cities"
    else if(type=="subcity")
         locationType= "subcities"
    else 
         locationType=type+"s"
    
  
      let locationId = this.audience.locations.map(res=> { return res.key; }).indexOf(key)
      
        //const interestUid = <number>await this.searchInterestArr(id);
        if(locationId >= 0){
          let locationId2 = this.targeting.geo_locations[locationType].map(res=> { return res.key; }).indexOf(key)
          this.audience.locations.splice(locationId, 1)
          this.targeting.geo_locations[locationType].splice(locationId2, 1)
          this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
            this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          })
        }
        
        locationId = this.audience.excludelocations.map(res=> { return res.key; }).indexOf(key)
          
            //const interestUid = <number>await this.searchInterestArr(id);
            if(locationId >= 0){
              let locationId2 = this.targeting.excluded_geo_locations[locationType].map(res=> { return res.key; }).indexOf(key)
              this.audience.excludelocations.splice(locationId, 1)
              this.targeting.excluded_geo_locations[locationType].splice(locationId2, 1)
              this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
                this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              })
            }
     
          this.ref.detectChanges();
          
          }
  
  
  
  
  placeChanged(place,option) {
          console.log(place)
          this.location.name= place.formatted_address
          this.location.latitude = place.geometry.location.lat()
          this.location.longitude = place.geometry.location.lng()
          console.log(this.location)
          
        if(option){
          this.audience.addresses.push(
            {name:this.location.name, 
             latitude:this.location.latitude,
             longitude:this.location.longitude,
             radius:this.location.radius,
             distance_unit:this.location.distance_unit,
             status:this.location.status,
             })
  
            this.targeting.geo_locations['custom_locations'].push({latitude: this.location.latitude,longitude: this.location.longitude,radius:16,distance_unit: 'kilometer'})}
        else{
          this.audience.excludeaddresses.push(
            {name:this.location.name, 
             latitude:this.location.latitude,
             longitude:this.location.longitude,
             radius:this.location.radius,
             distance_unit:this.location.distance_unit,
             status:this.location.status,
             })
  
            this.targeting.excluded_geo_locations['custom_locations'].push({latitude: this.location.latitude,longitude: this.location.longitude,radius:16,distance_unit: 'kilometer'})
        }
             this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
               this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
             })
           
           
        
          this.ref.detectChanges();
        }
  
  
  
    removeAddress(locationName){
      let locationId = this.audience.addresses.map(res=> { return res.name; }).indexOf(locationName)
        
          if(locationId >= 0){
            this.audience.addresses.splice(locationId, 1)
            this.targeting.geo_locations['custom_locations'].splice(locationId, 1)
            this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
              this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              return
            })
          }
  
        let locationId2 = this.audience.excludeaddresses.map(res=> { return res.name; }).indexOf(locationName)
        
          if(locationId2 >= 0){
            this.audience.excludeaddresses.splice(locationId, 1)
            this.targeting.excluded_geo_locations['custom_locations'].splice(locationId, 1)
            this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
              this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            })
          }
      
          this.ref.detectChanges();
        
        }
  
    
    
    
   selectChangeHandlerLocation(event: any) {
          this.locationOption = event.target.value;
          if(this.locationOption=="Include")
             this.optionStatus = true;
          else
          this.optionStatus = false;
            }
  
  
    
    onMapClick(event,option) {
        console.log(event)
     
         this.location.latitude = event.latLng.lat()
         this.location.longitude = event.latLng.lng()
         this.location.name = "("+this.location.latitude+","+this.location.longitude+")"
   
         if(option){
          this.audience.addresses.push(
            {name:this.location.name, 
             latitude:this.location.latitude,
             longitude:this.location.longitude,
             radius:this.location.radius,
             distance_unit:this.location.distance_unit,
             status:this.location.status,
             })
  
            this.targeting.geo_locations['custom_locations'].push({latitude: this.location.latitude,longitude: this.location.longitude,radius:16,distance_unit: "kilometer"})}
        else{
          this.audience.excludeaddresses.push(
            {name:this.location.name, 
             latitude:this.location.latitude,
             longitude:this.location.longitude,
             radius:this.location.radius,
             distance_unit:this.location.distance_unit,
             status:this.location.status,
             })
  
            this.targeting.excluded_geo_locations['custom_locations'].push({latitude: this.location.latitude,longitude: this.location.longitude,radius:16,distance_unit: "kilometer"})
        }
             this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
               this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
             })
  
          console.log(this.audience.addresses)
           
           
        
          this.ref.detectChanges();
        
     
     
         //event.target.panTo([event.latLng]);
     
     
       }
  
  
  showSlideBar(locationName,option){
    if(option) {
    for(let i=0;i<this.audience.addresses.length;i++){
  
      if(this.audience.addresses[i].name==locationName){
        this.audience.addresses[i].status=true
        break;}
      }}
    else{
      for(let i=0;i<this.audience.excludeaddresses.length;i++){
  
        if(this.audience.excludeaddresses[i].name==locationName){
          this.audience.excludeaddresses[i].status=true
          break;}
        }
    }
    this.ref.detectChanges();
  }
  
  
  
  showSlideBarLocation(locationName,option){
  if(option) {
    for(let i=0;i<this.audience.locations.length;i++){
  
      if(this.audience.locations[i].name==locationName){
        this.audience.locations[i].status=true
        break;}
      } }
  else{
    for(let i=0;i<this.audience.excludelocations.length;i++){
  
      if(this.audience.excludelocations[i].name==locationName){
        this.audience.excludelocations[i].status=true
        break;}
      } 
  }
    this.ref.detectChanges();
  }
  
  
  
  radiusChange(locationKey,option){
    console.log(locationKey)
    if(this.radius/10>=1 && this.radius/10<2)
      this.zoom=9
    else if(this.radius/10>=2 && this.radius/10<3)
      this.zoom=8
    else 
      this.zoom=7
  
  if(option){
  //this.location.radius= this.radius
  for(let i=0;i<this.audience.locations.length;i++){
  
  if(this.audience.locations[i].key==locationKey){
    this.audience.locations[i].radius=this.radius
    this.audience.locations[i].status=false
    console.log(this.targeting.geo_locations["cities"])
    let locationId = this.targeting.geo_locations["cities"].map(res=> { return res.key; }).indexOf(locationKey)
    console.log(locationId)
  
    this.targeting.geo_locations["cities"][locationId].radius=this.radius
  
  
    this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
      this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
    break;}
  }
  }
  
  else{
    for(let i=0;i<this.audience.excludelocations.length;i++){
  
      if(this.audience.excludelocations[i].key==locationKey){
        this.audience.excludelocations[i].radius=this.radius
        this.audience.excludelocations[i].status=false
        console.log(this.targeting.excluded_geo_locations["cities"])
        let locationId = this.targeting.excluded_geo_locations["cities"].map(res=> { return res.key; }).indexOf(locationKey)
        console.log(locationId)
      
        this.targeting.excluded_geo_locations["cities"][locationId].radius=this.radius
      
      
        this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
          this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
        break;}
      } 
  }
  
  this.ref.detectChanges();
  
  }



  addressRadiusChange(locationName,option){

    if(this.radius/10>=1 && this.radius/10<2)
      this.zoom=9
    else if(this.radius/10>=2 && this.radius/10<3)
      this.zoom=8
    else 
      this.zoom=7
    
    if(option){
    //this.location.radius= this.radius
    for(let i=0;i<this.audience.addresses.length;i++){
    
    if(this.audience.addresses[i].name==locationName){
      this.audience.addresses[i].radius=this.radius
      this.audience.addresses[i].status=false
      
      this.targeting.geo_locations["custom_locations"][i].radius=this.radius
    
    
      this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
        this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
      break;}
    }
    }
    
    else{
      for(let i=0;i<this.audience.excludeaddresses.length;i++){
    
        if(this.audience.excludeaddresses[i].name==locationName){
          this.audience.excludeaddresses[i].radius=this.radius
          this.audience.excludeaddresses[i].status=false
         
        
          this.targeting.excluded_geo_locations["custom_locations"][i].radius=this.radius
        
        
          this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
            this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
          break;}
        } 
    }
    
    this.ref.detectChanges();
    
    }
    
    
    
    
    
    
    
    //Age
    selectChangeHandlerMin (event: any) {
        //update the ui
        this.ageMin = event.target.value;
        this.audience.age_min=this.ageMin;
        this.targeting.age_min=this.ageMin;
        this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
          this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
          }
    
    selectChangeHandlerMax (event: any) {
            //update the ui
            this.ageMax = event.target.value;
            this.audience.age_max=this.ageMax;
            this.targeting.age_max=this.ageMax;
            this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
                this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
              }
    
    count(){
    for(let i=13;i<=65;i++)
    {
      this.counts.push(i)
    }
       
    }
    
    
    
    
    
    
    //Gender
    addGender(gender){
      this.audience.gender=gender
      console.log(this.audience.gender)
    
    if(gender==1){
      this.targeting.genders=[];
      this.targeting.genders.push(this.audience.gender);
      console.log(this.targeting.genders)
      this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
          this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
    }
    else if(gender==2){
      this.targeting.genders=[];
      this.targeting.genders.push(this.audience.gender);
      this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
          this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
    }
    else{
      this.targeting.genders=[];
      this.targeting.genders.push(1,2);
      this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
          this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
    }
    }
    
    
    
    
    
//Interests
addInterest(id, name, type, layer) {
  
  console.log(layer)
  let interestId = this.audience.interests[layer].map(res=> { return res.id; }).indexOf(id)
  
    
    if(interestId >= 0){
      let interestId2 = this.targeting.flexible_spec[layer][type].map(res=> { return res.id; }).indexOf(id)
      this.audience.interests[layer].splice(interestId, 1)
      this.targeting.flexible_spec[layer][type].splice(interestId2, 1)
      this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
        this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      })
    }
    else{
      this.audience.interests[layer].push({id, name,type})
      if(!(type in this.targeting.flexible_spec[layer]))
         this.targeting.flexible_spec[layer][type]=[]
      this.targeting.flexible_spec[layer][type].push({id:id,name:name})
      this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
        this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      })
    }
    this.interests['list']=null
    this.interests['name']=null
    

    console.log(this.audience)
  }




addExcludeInterest(id, name, type) {
    
  
    let interestId = this.audience.exclusion.map(res=> { return res.id; }).indexOf(id)
  
    
      if(interestId >= 0){
       let interestId2 = this.targeting.exclusions[type].map(res=> { return res.id; }).indexOf(id)
        this.audience.exclusion.splice(interestId, 1)
        this.targeting.exclusions[type].splice(interestId2, 1)
        this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
          this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        })
      }
      else{
        
        this.audience.exclusion.push({id, name,type})
        if(!(type in this.targeting.exclusions))
         this.targeting.exclusions[type]=[]
        this.targeting.exclusions[type].push({id:id,name:name})
        this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
          this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        })
      }
      this.interests['list']=null
      this.interests['name']=null
      console.log(this.audience)
    }



getInterest(e,status){
  this.interestStatus=status
    if(e.target.value.length == 0){
      this.interests['list'] = null
      this.noResults = true
      return
    }

  this.fbservice.searchAudience(e.target.value).subscribe( interest =>{
      console.log(interest)
      if(interest['interests'].length <= 0 || e.target.value == 0){
        this.noResults = true
        this.interests['list'] = null
      }else{
        this.noResults = false
        for(let i=0;i<interest['interests'].length;i++){
          
        interest['interests'][i].audience_size=interest['interests'][i].audience_size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        this.interests['list'] = interest['interests']
      }
    })
  }


changeExcludeStatus(){
  this.excludeStatus = true;
}


changeNarrowStatus(){
  this.narrowStatus = true;
  this.audience.interests.push([]);
  this.targeting.flexible_spec.push({});
  this.interestCount++
  this.items.push(this.interestCount);
}












//Language
getLanguage(e){
    if(e.target.value.length == 0){
      this.language['list'] = null
      this.noResultsLanguage = true
      return
    }

    this.fbservice.searchLanguage(e.target.value).subscribe( locale =>{
      console.log(locale)
      if(locale['locales'].length <= 0 || e.target.value == 0){
        this.noResultsLanguage = true
        this.language['list'] = null
      }else{
        this.noResults = false
        this.language['list'] = locale['locales']
      }
    })
  }



  addLanguage(key, name) {
    console.log(this.audience)
  
    let languageId = this.audience.languages.map(res=> { return res.key; }).indexOf(key)
  
     // const interestUid = <number>await this.searchInterestArr2(id);
      if(languageId >= 0){
        this.audience.languages.splice(languageId, 1)
        this.targeting.locales.splice(languageId, 1)
        this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
          this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        })
      }
      else{
        
        this.audience.languages.push({key, name})
        this.targeting.locales.push(key)
        this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
          this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        })
      }
      this.language['list']=null
      this.language['name']=null
    }






//Connections
selectChangeHandler(event: any) {
  this.connection = event.target.value;
  this.audience.connection=this.connection;
  this.showConnection=true;
  this.connectionOption= this.connection

  if(this.connectionOption=="People who like your Page"){
    this.audience.friendsconnectionPages=[]
    this.audience.excludeconnectionPages=[]
    this.targeting.friends_of_connections=[]
    this.targeting.excluded_connections=[]
  }
  else if(this.connectionOption=="Friends of people who like your Page"){
    this.audience.connectionPages=[]
    this.audience.excludeconnectionPages=[]
    this.targeting.connections=[]
    this.targeting.excluded_connections=[]
  }
  else{
    this.audience.connectionPages=[]
    this.audience.excludeconnectionPages=[]
    this.targeting.connections=[]
    this.targeting.excluded_connections=[]
  }

    }




    
selectChangeHandlerPage(event: any) {
  this.pageName = event.target.value;
  let pageId = this.page.map(res=> { return res.name; }).indexOf(this.pageName)
    
if(this.connectionOption=="People who like your Page"){
  
  let pageId2 = this.audience.connectionPages.map(res=> { return res.name; }).indexOf(this.pageName)

  if(pageId2<0){
    this.audience.connectionPages.push({id:this.page[pageId].id,name:this.pageName})
    this.targeting.connections.push({id:this.page[pageId].id})}}
 
    
  
else if(this.connectionOption=="Friends of people who like your Page"){
  let pageId2 = this.audience.friendsconnectionPages.map(res=> { return res.name; }).indexOf(this.pageName)

  if(pageId2<0){
    this.audience.friendsconnectionPages.push({id:this.page[pageId].id,name:this.pageName})
    this.targeting.friends_of_connections.push({id:this.page[pageId].id})}}




  else
  {
    let pageId2 = this.audience.excludeconnectionPages.map(res=> { return res.name; }).indexOf(this.pageName)

    if(pageId2<0){
      this.audience.excludeconnectionPages.push({id:this.page[pageId].id,name:this.pageName})
      this.targeting.excluded_connections.push({id:this.page[pageId].id})}}
  
  
  this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
          this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        })

    }

removePages(pageName){
  let pageId = this.audience.connectionPages.map(res=> { return res.name; }).indexOf(pageName)
  
     
        this.audience.connectionPages.splice(pageId, 1)
       /* this.targeting.locales.splice(pageId, 1)
        this.fbservice.getReachEstimate(this.targeting).subscribe( estimate =>{
          this.audienceSize=estimate['estimate'].users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        })
      }*/ 
}

getPageInfo(uid){
  this.fbservice.getPageInfo(uid).subscribe( page =>{
  this.page=page['pages']
  this.pageNumber=page['pages'].length
  console.log(this.page) 
})

}    
  


  changeEngagementStatus(){
  this.engagementStatus=true;
  }




 

  

 
  
  
  
  
      


}
