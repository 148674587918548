import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InterestSelectorComponent } from './components/interest-selector/interest-selector.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { SpecialAdSelectorComponent } from './components/special-ad-selector/special-ad-selector.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InterestsPickerComponent } from './components/interests-picker/interests-picker.component';
import { SortByKeyPipe } from './pipes/sort-by-key.pipe';


@NgModule({
  declarations: [InterestSelectorComponent, LanguageSelectorComponent, SpecialAdSelectorComponent, InterestsPickerComponent, SortByKeyPipe],
  imports: [CommonModule, NgbModule],
  exports: [
    FlexLayoutModule,
    InterestSelectorComponent,
    LanguageSelectorComponent,
    SpecialAdSelectorComponent,
    SortByKeyPipe,
    NgbModule,
    InterestsPickerComponent,
  ]
})
export class SharedModule {}
