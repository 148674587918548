import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { Router } from '@angular/router';
import { FirebaseService } from '../../../services/firebase.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

  public rooms: any;
  private username = 'AdvozAdmin';


  constructor(
    private db: AngularFirestore,
    private router: Router,
    private firebaseService: FirebaseService
  ) {}

  ngOnInit() {
    this.loadUserRooms(this.username);
  }

  private loadUserRooms(userID: string) {
    this.firebaseService.getFirebaseFunction('get_user_rooms?user_id=' + userID, { user_id: userID }).subscribe(
      (rooms) => {
        console.log(rooms);
        this.rooms = rooms;
      }
    );
  }

  public redirect() {
    this.router.navigate(['/chatdetails']);
  }

}
