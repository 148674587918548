import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgReduxModule } from '@angular-redux/store';
import { NgRedux, DevToolsExtension } from '@angular-redux/store';
import { rootReducer, ArchitectUIState } from './ThemeOptions/store';
import { ConfigActions } from './ThemeOptions/store/config.actions';
import { AppRoutingModule } from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NouisliderModule } from 'ng2-nouislider';
import { NguiMapModule } from '@ngui/map';
import { MatSliderModule, GestureConfig } from '@angular/material';
import { AuthGuard } from './core/auth/auth.guard';

// BOOTSTRAP COMPONENTS

import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

// Pipes
import { FileSizePipe } from './FileSize.pipe';

// LAYOUT

import {BaseLayoutComponent} from './Layout/base-layout/base-layout.component';
import {PagesLayoutComponent} from './Layout/pages-layout/pages-layout.component';
import {PageTitleComponent} from './Layout/Components/page-title/page-title.component';

// HEADER

import {HeaderComponent} from './Layout/Components/header/header.component';
import {SearchBoxComponent} from './Layout/Components/header/elements/search-box/search-box.component';
import {UserBoxComponent} from './Layout/Components/header/elements/user-box/user-box.component';

// SIDEBAR

import {SidebarComponent} from './Layout/Components/sidebar/sidebar.component';
import {LogoComponent} from './Layout/Components/sidebar/elements/logo/logo.component';

// FOOTER

import {FooterComponent} from './Layout/Components/footer/footer.component';

// DEMO PAGES

// Dashboards

import { ProfileComponent } from './DemoPages/Dashboards/profile/profile.component';
import { LoginBoxedComponent } from './DemoPages/Dashboards/login-boxed/login-boxed.component';
import { UserComponent } from './DemoPages/Dashboards/user/user.component';
// Pages

import {ForgotPasswordBoxedComponent} from './DemoPages/UserPages/forgot-password-boxed/forgot-password-boxed.component';
import {RegisterBoxedComponent} from './DemoPages/UserPages/register-boxed/register-boxed.component';
import { LoginBoxComponent } from './DemoPages/UserPages/login-boxed/login-boxed.component';

// Elements

import {StandardComponent} from './DemoPages/Elements/Buttons/standard/standard.component';
import {DropdownsComponent} from './DemoPages/Elements/dropdowns/dropdowns.component';
import {CardsComponent} from './DemoPages/Elements/cards/cards.component';
import {ListGroupsComponent} from './DemoPages/Elements/list-groups/list-groups.component';
import {TimelineComponent} from './DemoPages/Elements/timeline/timeline.component';
import {IconsComponent} from './DemoPages/Elements/icons/icons.component';

// Components

import {AccordionsComponent} from './DemoPages/Components/accordions/accordions.component';
import {TabsComponent} from './DemoPages/Components/tabs/tabs.component';
import {CarouselComponent} from './DemoPages/Components/carousel/carousel.component';
import {ModalsComponent} from './DemoPages/Components/modals/modals.component';
import {ProgressBarComponent} from './DemoPages/Components/progress-bar/progress-bar.component';
import {PaginationComponent} from './DemoPages/Components/pagination/pagination.component';
import {TooltipsPopoversComponent} from './DemoPages/Components/tooltips-popovers/tooltips-popovers.component';

// Tables

import {RegularComponent} from './DemoPages/Tables/regular/regular.component';
import {TablesMainComponent} from './DemoPages/Tables/tables-main/tables-main.component';

// Widgets

import {ChartBoxes3Component} from './DemoPages/Widgets/chart-boxes3/chart-boxes3.component';

// Forms Elements

import {ControlsComponent} from './DemoPages/Forms/Elements/controls/controls.component';
import {LayoutComponent} from './DemoPages/Forms/Elements/layout/layout.component';

import {environment} from '../environments/environment';
import {AngularFireModule} from 'angularfire2';
import {AngularFirestoreModule} from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireAuthModule } from 'angularfire2/auth';

// Services
import {FirebaseService} from './services/firebase.service';
import {AlertService} from './services/alert.service';
import {Globals} from './services/globals.service';
import {TransferService} from './services/transfer.service';
import { AudienceComponent } from './DemoPages/Dashboards/audience/audience.component';
import { ContentComponent } from './DemoPages/Dashboards/content/content.component';
import { AudiencedetailComponent } from './DemoPages/Dashboards/audiencedetail/audiencedetail.component';
import { ContentdetailComponent } from './DemoPages/Dashboards/contentdetail/contentdetail.component';
import { CopydetailComponent } from './DemoPages/Dashboards/copydetail/copydetail.component';
import { InfluencerdetailComponent } from './DemoPages/Dashboards/influencerdetail/influencerdetail.component';
import { CampaigndetailComponent } from './DemoPages/Dashboards/campaigndetail/campaigndetail.component';
import { CopyComponent } from './DemoPages/Dashboards/copy/copy.component';
import { InfluencerComponent } from './DemoPages/Dashboards/influencer/influencer.component';
import { CampaignComponent } from './DemoPages/Dashboards/campaign/campaign.component';
import { ChatComponent } from './DemoPages/Dashboards/chat/chat.component';
import { FileDropDirective } from './file-drop.directive';
import { IndustryComponent } from './DemoPages/Dashboards/industry/industry.component';
import { ChatdetailComponent } from './DemoPages/Dashboards/chatdetail/chatdetail.component';
import { ProfileService } from './core/user/services/profile.service';
import { AuthService } from './core/auth/auth.service';
import { SharedModule } from './shared/shared.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    // LAYOUT

    AppComponent,
    BaseLayoutComponent,
    PagesLayoutComponent,
    PageTitleComponent,

    // PIPES
    FileSizePipe,
    // HEADER

    HeaderComponent,
    SearchBoxComponent,
    UserBoxComponent,

    // SIDEBAR

    SidebarComponent,
    LogoComponent,

    // FOOTER

    FooterComponent,

    // DEMO PAGES

    // User Pages

    ForgotPasswordBoxedComponent,
    LoginBoxedComponent,
    RegisterBoxedComponent,
    LoginBoxComponent,

    // Elements

    StandardComponent,
    IconsComponent,
    DropdownsComponent,
    CardsComponent,
    ListGroupsComponent,
    TimelineComponent,

    // Components

    AccordionsComponent,
    TabsComponent,
    CarouselComponent,
    ModalsComponent,
    ProgressBarComponent,
    PaginationComponent,
    TooltipsPopoversComponent,

    // Tables

    RegularComponent,
    TablesMainComponent,

    // Dashboard Boxes

    ChartBoxes3Component,

    // Form Elements

    ControlsComponent,
    LayoutComponent,

    // Other components

    ProfileComponent,
    LoginBoxedComponent,
    UserComponent,
    AudienceComponent,
    ContentComponent,
    AudiencedetailComponent,
    ContentdetailComponent,
    CopydetailComponent,
    InfluencerdetailComponent,
    CampaigndetailComponent,
    CopyComponent,
    InfluencerComponent,
    CampaignComponent,
    ChatComponent,
    FileDropDirective,
    IndustryComponent,
    ChatdetailComponent,
  ],
  imports: [
    NguiMapModule.forRoot({
      apiUrl: 'https://maps.google.com/maps/api/js?libraries=visualization,places,drawing&key=AIzaSyCTsP0aYVCr7TbjqYmDob_NmfpO-SSWcs4'
    }),
    MatSliderModule,
    AngularFireAuthModule,

    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    NgReduxModule,
    CommonModule,
    LoadingBarRouterModule,

    // Angular Bootstrap Components

    PerfectScrollbarModule,
    AngularFontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // Charts
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebase, 'angularfs'),
    AngularFirestoreModule,
    NouisliderModule,
    SharedModule
  ],
  providers: [
    {
      provide:
      PERFECT_SCROLLBAR_CONFIG,

      // DROPZONE_CONFIG,
      useValue:
      DEFAULT_PERFECT_SCROLLBAR_CONFIG,
      // DEFAULT_DROPZONE_CONFIG,
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },

    ConfigActions,
    FirebaseService,
    AlertService,
    Globals,
    TransferService,
    ProfileService,
    AuthGuard,
    AuthService
  ],
  bootstrap: [AppComponent],
  exports: [FileSizePipe]
})

export class AppModule {
  constructor(private ngRedux: NgRedux<ArchitectUIState>,
              private devTool: DevToolsExtension) {

    this.ngRedux.configureStore(
      rootReducer,
      {} as ArchitectUIState,
      [],
      [devTool.isEnabled() ? devTool.enhancer() : f => f]
    );

  }
}
